import styled from 'styled-components';
import { VoxColors } from 'themes/Colors';

export const UserName = styled.div`
  margin-top: 50px;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  text-shadow: 0 4px 25px rgba(215, 89, 123, 0.4);
  font-family: Outage, sans-serif;
  font-weight: bold;
  background: linear-gradient(to right, ${VoxColors.voidNeon} 0%, ${VoxColors.candy6} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const WalletWrapperGrid = styled.div`
  position: relative;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  width: 269px;
  column-gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

export const AccountWallet = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;

  button {
    color: ${VoxColors.tron5};
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.01em;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-family: 'IBM Plex Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    position: relative;
    top: 12px;
    height: 40px;
    transition: all 0.2s ease-in;

    &:after {
      display: none;
      position: absolute;
      top: 22px;
      content: 'Click to copy address';
      color: #ddd;
      font-size: 12px;
      white-space: nowrap;
      transition: all 0.5s ease-out;
      background: rgba(42, 64, 96, 0.5);
      box-shadow: 1px 2px 80px rgba(11, 21, 40, 0.5);
      border-radius: 2px;
      padding: 7px 10px;
      font-family: 'IBM Plex Sans', sans-serif;
      letter-spacing: 0.01em;
    }
    &:hover {
      color: #ffffff;
      &:after {
        display: block;
      }
    }
    &:focus {
      outline: none;
      &:after {
        content: 'Copied!';
        color: #56f6ce;
        font-weight: bold;
      }
    }
  }
`;

export const VerticalLine = styled(AccountWallet)`
  font-weight: 500;
  line-height: 20px;
  color: ${VoxColors.white};
`;

export const DisconnectLink = styled(AccountWallet)`
  font-weight: 700;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const VoxosNumbersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 520px;
  height: 40px;
  background: #0e1c34;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding-left: 78px;
  padding-right: 88px;
`;

export const VoxosNumberContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 375px;
  &::before {
    content: '';
    position: absolute;
    width: 323px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    left: -500px;
    top: 50%;
  }
  &::after {
    content: '';
    position: absolute;
    width: 323px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    left: 432px;
    top: 50%;
  }
`;

export const VoxosNumbersBlock = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-right: 50px;
  }
`;

export const VoxosNumbersTitle = styled.div`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 11px;
  color: #bdb9dd;
  letter-spacing: 0.1em;
  opacity: 0.5;
`;

export const VoxosNumbers = styled.div`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${VoxColors.white};
`;
