import styled from 'styled-components';

export const TextNoVoxos = styled.div`
  margin-top: 80px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  max-width: 575px;
  margin-left: auto;
  margin-right: auto;
`;
