import React from 'react';
import {
  UserName,
  WalletWrapperGrid,
  AccountWallet,
  DisconnectLink,
  VerticalLine,
  VoxosNumbersWrapper,
  VoxosNumbersTitle,
  VoxosNumbers,
  VoxosNumbersBlock,
  VoxosNumberContainer,
} from './myCollectionGalleryHeader.styled';

import { accountTrunc } from 'utils/textFormat';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useWeb3React } from '@web3-react/core';

const MyCollectionGalleryHeader = ({ collectionData, account, userName }) => {
  const { deactivate } = useWeb3React();
  const { voxoIds, voxosMinted } = collectionData;

  const DisconnectFromMetaMask = () => {
    deactivate();
  };

  return (
    <>
      <UserName>{userName}</UserName>
      <WalletWrapperGrid>
        <AccountWallet>
          <CopyToClipboard text={account}>
            <button>{accountTrunc(account)}</button>
          </CopyToClipboard>
        </AccountWallet>
        <VerticalLine>|</VerticalLine>
        <DisconnectLink onClick={DisconnectFromMetaMask}>Disconnect</DisconnectLink>
      </WalletWrapperGrid>
      <VoxosNumbersWrapper>
        <VoxosNumberContainer>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>VOXOS MINTED</VoxosNumbersTitle>
            <VoxosNumbers>{voxosMinted}</VoxosNumbers>
          </VoxosNumbersBlock>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>VOXOS OWNED</VoxosNumbersTitle>
            <VoxosNumbers>{voxoIds.length}</VoxosNumbers>
          </VoxosNumbersBlock>
        </VoxosNumberContainer>
      </VoxosNumbersWrapper>
    </>
  );
};

export default MyCollectionGalleryHeader;
