import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, walletLink } from 'components/Connector/connector';
import React from 'react';
import { Connector, ConnectorsContainer, ConnectorType, LogoContainer } from './dialoguesContex.styled';
import metamaskLogo from 'assets/icons/metamask_logo.png';
import walletConnectLogo from 'assets/icons/walletconnect_logo.png';
import walletLinkLogo from 'assets/icons/walletlink_logo.png';

const SelectConnector = ({ toggleShowSelectConnectorPage, smallImages }) => {
  const { activate, deactivate } = useWeb3React();

  const handleConnectClick = async () => {
    try {
      await deactivate();
      await injected.isAuthorized();
      await activate(injected);
      // toggleShowSelectConnectorPage(false);
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleWalletConnectClick = async () => {
    try {
      await localStorage.removeItem('walletconnect');
      await deactivate();
      await activate(walletconnect);
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleWalletLinkClick = async () => {
    try {
      await deactivate();
      await activate(walletLink);
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <>
      <ConnectorsContainer smallImages={smallImages}>
        <Connector onClick={handleConnectClick} smallImages={smallImages}>
          <LogoContainer smallImages={smallImages}>
            <img src={metamaskLogo} />
          </LogoContainer>
          <ConnectorType smallImages={smallImages}>Metamask</ConnectorType>
        </Connector>
        <Connector onClick={handleWalletConnectClick} smallImages={smallImages}>
          <LogoContainer smallImages={smallImages}>
            <img src={walletConnectLogo} />
          </LogoContainer>
          <ConnectorType smallImages={smallImages}>WalletConnect</ConnectorType>
        </Connector>
        <Connector onClick={handleWalletLinkClick} smallImages={smallImages}>
          <LogoContainer smallImages={smallImages}>
            <img src={walletLinkLogo} />
          </LogoContainer>
          <ConnectorType smallImages={smallImages}>WalletLink</ConnectorType>
        </Connector>
      </ConnectorsContainer>
    </>
  );
};

export default SelectConnector;
