import React from 'react';
import { MintButton, BrowseButton } from '../../myCollectionPage.styled';
import { TextNoVoxos, ButtonContainer } from './noVoxosOwned.styled';
import { ReactComponent as OpenSea } from 'assets/icons/open_sea.svg';

const NoVoxosOwned = () => {
  return (
    <div>
      <TextNoVoxos>Nothin’ but VoxoDust here...</TextNoVoxos>
      <ButtonContainer>
        <MintButton>
          <span>Mint a Voxo</span>
        </MintButton>
        <BrowseButton>
          <OpenSea />
          <span>Browse for Voxos</span>
        </BrowseButton>
      </ButtonContainer>
    </div>
  );
};

export default NoVoxosOwned;
