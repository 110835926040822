import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {
  ConnectMetamaskModal,
  ConnectMetamaskModalBody,
  ButtonsContainer,
  TextBlockWrapper,
  ConnectMetamaskModalOriginal,
  ConnectMetamaskModalBodyOriginal,
  BgImg,
  ContentWrapper,
  ContentWrapperAlign
} from './dialoguesContex.styled';
import ConnectToSeeRewards from './ConnectToSeeRewards';
import SwitchToMainnet from './SwitchToMainnet';
import {ColoredButton, SimpleButton} from '../rewardsPage.styled';
import {useWeb3React} from '@web3-react/core';
import SelectConnector from './SelectConnector';
import {ReactComponent as FwIcon} from 'assets/icons/chevron_right.svg';
import BackgroundRewards from 'assets/images/BackgroundRewards.png'

const SwitchText = ({isConnected, chainId, error}) => {
  if ((chainId && chainId !== 1) || (error && error.name === 'UnsupportedChainIdError')) return <SwitchToMainnet/>;
  if (!isConnected) return <ConnectToSeeRewards/>;
  return null;
};

const DialoguesContext = ({isConnected}) => {
  const {error, chainId} = useWeb3React();
  const history = useHistory();

  const [showSelectConnectorPage, toggleShowSelectConnectorPage] = useState(false);

  return (
      <div style={{height: `calc(100vh - 198px)`}}>
        <ContentWrapper>
          <ContentWrapperAlign>
            <BgImg>
              <img src={BackgroundRewards}/>
            </BgImg>
            {showSelectConnectorPage ? (
                <ConnectMetamaskModalOriginal>
                  <ConnectMetamaskModalBodyOriginal>
                    <SelectConnector toggleShowSelectConnectorPage={toggleShowSelectConnectorPage}/>
                  </ConnectMetamaskModalBodyOriginal>
                </ConnectMetamaskModalOriginal>
            ) : (
                <ConnectMetamaskModal>
                  <ConnectMetamaskModalBody>
                    <TextBlockWrapper>
                      <SwitchText isConnected={isConnected} chainId={chainId} error={error}/>
                    </TextBlockWrapper>
                    {(chainId && chainId !== 1) || (error && error.name === 'UnsupportedChainIdError') ? null : (
                        <ButtonsContainer>
                          <SimpleButton onClick={() => history.push('/')}>
                            <span>Cancel</span>
                          </SimpleButton>
                          <ColoredButton onClick={() => toggleShowSelectConnectorPage(true)}>
                            <span>Continue</span>
                            <FwIcon style={{marginRight: 20}}/>
                          </ColoredButton>
                        </ButtonsContainer>
                    )}
                  </ConnectMetamaskModalBody>
                </ConnectMetamaskModal>
            )}
          </ContentWrapperAlign>
        </ContentWrapper>
      </div>
  );
};

export default DialoguesContext;
