import styled from 'styled-components';

export const Header = styled.div`
  font-size: ${({ showPage }) => (showPage ? `16px` : `38px`)};
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 4px 25px rgba(215, 89, 123, 0.4);
  font-family: ${({ showPage }) => (showPage ? `"IBM Plex Sans", sans-serif` : `'Outage', sans-serif`)};
  text-transform: ${({ showPage }) => (showPage ? `uppercase` : `unset`)};
  letter-spacing: ${({ showPage }) => (showPage ? `0.15em` : `unset`)};
  transition: all 0.2s ease-out;
`;

export const CollectionPageStyle = styled.div`
  margin-top: 150px;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

export const ColoredButton = styled.div`
  border-radius: 10px 3px 3px 3px;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    box-shadow: inset 0 0 0 2px #8c86ff;
    > span {
      transform: scale(1.05);
    }
  }
  > span {
    display: flex;
    align-items: center;
    color: #fff;
    transition: all 0.1s ease-out;
  }
`;

export const MintButton = styled(ColoredButton)`
  width: 280px;
`;

export const BrowseButton = styled(MintButton)`
  margin-left: 16px;
  background: #2081e2;
  column-gap: 15px;
`;
