import React from "react";

const SwitchToMainnet = () => {
  return (
    <>
      <p>Please, switch to Ethereum Mainnet</p>
    </>
  );
};

export default SwitchToMainnet;
