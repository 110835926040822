import React from 'react';
import {CollectionPageStyle, DesktopOnlyBlock, DesktopOnlyText, InnerText, ToDektopButton} from './mobilePage.styled';
import {ReactComponent as FwIcon} from "../../assets/icons/chevron_right.svg";
import {useHistory} from "react-router-dom";

const MobilePage = () => {
  const history = useHistory();

  return (
    <CollectionPageStyle>
      <DesktopOnlyBlock>
        <DesktopOnlyText>DESKTOP ONLY</DesktopOnlyText>
        <InnerText>
          These parts of the site require MetaMask so will only work on the desktop site.
        </InnerText>
        <ToDektopButton onClick={() => history.push({ pathname: '/' })}>
          <span>Return home</span>
          <FwIcon />
        </ToDektopButton>
      </DesktopOnlyBlock>
    </CollectionPageStyle>
  );
};

export default MobilePage;
