import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

export const injected = new InjectedConnector({
  supportedChainIds: process.env.REACT_APP_ENV !== 'prod' || window.location.host.includes('voxo-web') ? [1, 4] : [1],
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: 'https://mainnet.infura.io/v3/d7e8be4af3064c27ad389ede8aa9ac95' },
  qrcode: true,
  pollingInterval: 8000,
});
export const walletLink = new WalletLinkConnector({
  url: 'https://mainnet.infura.io/v3/d7e8be4af3064c27ad389ede8aa9ac95',
  appName: 'Voxo',
});
