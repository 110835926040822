import styled from 'styled-components';
import * as color from 'assets/CSSPalette/colors';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgb(67 88 122 / 70%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Modal = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 5px 61px rgba(225, 119, 144, 0.1);
  border-radius: 10px;
  background: #0b1528;
  color: #fff;
`;
export const ModalBody = styled.div`
  padding: 50px 45px 40px 45px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const VoxoId = styled.div`
  color: ${color.TRON_1};
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
`;
export const ModalHeader = styled.div`
  font-size: 28px;
  background: linear-gradient(86.26deg, #8c86ff 3.09%, #ab5fb4 50.76%, #cc3767 100%);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  font-family: 'Outage';
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  position: relative;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
`;
export const ContinueButton = styled.div`
  background: linear-gradient(86.26deg, #8c86ff 3.09%, #ab5fb4 50.76%, #cc3767 100%);
  border-radius: 10px 3px 3px 3px;
  width: 141px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: linear-gradient(86.26deg, #cc3767 3.09%, #ab5fb4 50.76%, #8c86ff 100%);
  }
`;

export const Timer = styled.div`
  color: #e77e65;
  font-weight: 600;
  margin-left: 8px;
`;
export const ClickToRefresh = styled.div`
  color: #5186eb;
  font-weight: 600;
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    color: ${color.CANDY_NEON};
  }
`;
export const TextBlock = styled.div`
  color: #b3c3e2;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  display: flex;
`;
export const Files = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 32px;
`;
export const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
export const SignMessage = styled.div`
  font-size: 16px;
  line-height: 140%;
  opacity: 0.9;
`;
export const FilesListHeader = styled.div`
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 130%;
  letter-spacing: 0.1em;
  margin-bottom: 21px;
`;
export const File = styled.div`
  color: ${color.TRON_6};
  font-weight: 500;
  font-size: 14px;
  line-height: 255%;
  letter-spacing: 0.01em;
  cursor: ${({ inTime }) => (inTime ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ inTime }) => (inTime ? 'unset' : 'none')};
  display: flex;
  align-items: center;
  opacity: ${({ inTime }) => (inTime ? 1 : 0.2)};
  > svg {
    margin-right: 16px;
  }
  &:hover {
    color: ${({ inTime }) => (inTime ? color.CANDY_NEON : color.TRON_6)};
  }
`;

export const CloseModalButton = styled.div`
  position: absolute;
  right: 106px;
  top: 65px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  transition: all 0.15s ease-out;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: #fff;
  }
  &:hover {
    color: #ff6a84;
  }
`;
