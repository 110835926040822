import styled from "styled-components";
import * as color from "assets/CSSPalette/colors";
import CHECK from "assets/icons/check_24px.svg";

export const CheckboxContainer = styled.div`
  display: flex;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
`;

export const CheckboxInput = styled.input`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  z-index: 1;
  opacity: 0;
`;
export const CheckboxImage = styled.div`
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: ${({ isChecked }) => (isChecked ? "#fff" : "unset")};
  border: 1px solid ${color.TRON_2};
  &:after {
    content: url(${({ isChecked }) => (isChecked ? CHECK : "")});
    position: relative;
    left: 1px;
    top: -2px;
    width: 16px;
  }
  margin-right: 8px;
`;

export const CheckboxLabel = styled.div``;
