import React, { useEffect } from 'react';
import {
  CheckboxGroupLine,
  CheckboxGroupTitle,
  CloseFiltersButton,
  FiltersQnt,
  FlexDiv,
  GalleryControls,
  MobileResetButton,
  MobileSubmitButton,
  ResetFiltersButton,
} from '../gallery.styled';
import Select from 'components/Select';
import ResetIcon from 'assets/icons/reset_icon.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_icon.svg';

import CheckboxGroup from '../CheckboxGroup';
import { useHistory } from 'react-router';
import { FilterSelectItem } from './filters.styled';

const SORTING = [
  {
    value: 'RarityAsc',
    displayName: (
      <FilterSelectItem asc>
        Rarity rank <ArrowUpIcon />
      </FilterSelectItem>
    ),
  },
  {
    value: 'RarityDesc',
    displayName: (
      <FilterSelectItem>
        Rarity rank <ArrowUpIcon />
      </FilterSelectItem>
    ),
  },
  {
    value: 'Ascending',
    displayName: (
      <FilterSelectItem asc>
        Voxo # <ArrowUpIcon />
      </FilterSelectItem>
    ),
  },
  {
    value: 'Descending',
    displayName: (
      <FilterSelectItem>
        Voxo # <ArrowUpIcon />
      </FilterSelectItem>
    ),
  },
];
const initialSpecies = {
  all: false,
  Omega: false,
  Ascendii: false,
  Exomorphs: false,
  Augs: false,
  Sapiens: false,
  Robits: false,
};
const initialRarity = {
  all: false,
  Rare: false,
  'Super Rare': false,
  'Ultra Rare': false,
  Unique: false,
  'The Only': false,
};
const initialFactions = {
  all: false,
  'The Shen Tanks': false,
  Polarion: false,
  'Corpo Vendrix': false,
  'Luma Systemica': false,
  Ω: false,
};
const initialArchetypes = {
  all: false,
  'Street Samurai': false,
  'Moongazer': false,
  'Honda Luca': false,
  'Eliteress': false,
  'Ch3f 3.0': false,
  'Deliverobit': false,
  'Floristobit': false,
  'Gadgeteer': false,
  'HV-808': false,
  'HV-Clothier': false,
  'HV-UN-1T': false,
  'Ice Hauler': false,
  'Medicaller': false,
  'Mech Decker': false,
  'Starcatcher': false,
  'Photon Ranger': false,
  'Cable Monk': false,
  'Rig Pirate': false,
  'Valkyrie Zero': false,
  'Orbiteer': false,
  'Tiny': false,
  'Velvet Bull': false,
  'Whiskers': false,
  'The Abyss': false,
  'The Betrayal': false,
  'The Fee': false,
  'The Journey': false,
  'The Many': false,
  'The Polarity': false,
  'The Seed': false,
  'The Sentinel': false,
  'Deus': false,
};
const Filters = ({ filters, setFilters, toggleShowFilters, showFilters, appliedFilters, urlParams }) => {
  const history = useHistory();

  useEffect(() => {
    if (urlParams) {
      decodingUrlParams(urlParams);
    } else {
      setFilters({
        voxoId: '',
        sortBy: 'Ascending',
        species: initialSpecies,
        rarity: initialRarity,
        factions: initialFactions,
        archetypes: initialArchetypes,
      });
    }
  }, []);

  const getStringifiedObject = (filtersObject, target) => {
    const encodingString = getEncodingString(filtersObject, target);
    return encodingString ? { [target]: encodingString } : {};
  };

  const getEncodingString = (filtersObj, target) => {
    if (filtersObj[target]['all']) {
      return 'all';
    }

    const encondingString = Object.keys(filtersObj[target]).reduce((out, filter) => {
      if (filtersObj[target][filter]) {
        if (out) {
          out = `${out};${filter}`;
        } else {
          out = filter;
        }
      }
      return out;
    }, '');

    return encondingString;
  };

  const getDecodingObject = (encodingString, initialValues) => {
    const out = { ...initialValues };

    if (!encodingString) return out;

    if (encodingString === 'all') {
      for (let key in out) {
        out[key] = true;
      }
      return out;
    }

    return {
      ...out,
      ...encodingString.split(';').reduce((out, item) => {
        out[item] = true;
        return out;
      }, {}),
    };
  };

  const urlFiltersToObject = urlFilters => {
    const arr = urlFilters.replace('?filters=', '').replace(/_/g, ' ').split(',');
    return arr.reduce((out, item) => {
      const arrItem = item.split(':');
      out[arrItem[0]] = arrItem[1] ? arrItem[1] : '';
      return out;
    }, {});
  };

  const decodingUrlParams = urlParams => {
    const parsedUrl = urlFiltersToObject(urlParams);
    setFilters({
      voxoId: parsedUrl.voxoId ? parsedUrl.voxoId : '',
      sortBy: parsedUrl.sortBy,
      species: { ...getDecodingObject(parsedUrl.species, initialSpecies) },
      rarity: { ...getDecodingObject(parsedUrl.rarity, initialRarity) },
      factions: { ...getDecodingObject(parsedUrl.factions, initialFactions) },
      archetypes: { ...getDecodingObject(parsedUrl.archetypes, initialArchetypes) },
    });
  };

  const prepareVoxoId = voxoId => {
    if (voxoId && parseInt(voxoId)) return { voxoId: parseInt(voxoId) || '' };
    return {};
  };
  const prepareSortBy = sortBy => sortBy.replace(/\s/g, '');
  useEffect(() => {
    if (filters) {
      const filterToUrl = {
        ...prepareVoxoId(filters.voxoId),
        sortBy: prepareSortBy(filters.sortBy),
        ...getStringifiedObject(filters, 'species'),
        ...getStringifiedObject(filters, 'rarity'),
        ...getStringifiedObject(filters, 'factions'),
        ...getStringifiedObject(filters, 'archetypes'),
      };

      history.push({
        search: `filters=${JSON.stringify(filterToUrl)}`
          .replace(/{/g, '')
          .replace(/}/g, '')
          .replace(/\s/g, '_')
          .replace(/"/g, ''),
      });
    }
  }, [filters]);

  const resetFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach(filter => {
      if (typeof filters[filter] === 'object' && filters[filter] !== null) {
        Object.keys(filters[filter]).forEach(v => {
          filters[filter][v] = false;
        });
      }
    });
    setFilters(newFilters);
  };

  return (
    <GalleryControls showFilters={showFilters}>
      <CloseFiltersButton onClick={() => toggleShowFilters(false)}>✕</CloseFiltersButton>
      <MobileResetButton>
        <FlexDiv>
          <ResetFiltersButton onClick={resetFilters}>
            <img src={ResetIcon} alt="reset" />
            Reset all
          </ResetFiltersButton>
          {!!appliedFilters && <FiltersQnt>{appliedFilters}</FiltersQnt>}
        </FlexDiv>
        <CheckboxGroupLine />
      </MobileResetButton>
      {filters && (
        <>
          <CheckboxGroupTitle>SORT BY</CheckboxGroupTitle>
          <Select
            data={SORTING}
            onChange={val => setFilters({ ...filters, sortBy: val })}
            value={SORTING.find(({ value }) => value === filters.sortBy)}
          />
          <CheckboxGroup target="species" filters={filters} setFilters={setFilters} />
          <CheckboxGroup target="rarity" filters={filters} setFilters={setFilters} max={4} />
          <CheckboxGroup target="factions" filters={filters} setFilters={setFilters} />
          <CheckboxGroup target="archetypes" filters={filters} setFilters={setFilters} withSearch max={5} />
          <ResetFiltersButton onClick={resetFilters}>
            <img src={ResetIcon} alt="reset" />
            Reset all
          </ResetFiltersButton>
        </>
      )}
      <MobileSubmitButton onClick={() => toggleShowFilters(false)}>Submit</MobileSubmitButton>
    </GalleryControls>
  );
};
export default Filters;
