import styled from 'styled-components';
import * as color from "../../assets/CSSPalette/colors";

export const CollectionPageStyle = styled.div`
  margin-top: -70px;
  color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const DesktopOnlyBlock = styled.div`
  position: relative;
  border-radius: 10px;
  width: 335px;
  height: 250px;
  padding: 40px 30px;
  margin: 40px auto;
  align-items: start;
  background: #fff;
`;

export const DesktopOnlyText = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  text-transform: uppercase;
  color: ${color.TRON_5};
`;

export const InnerText = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${color.TRON_1};
  margin-top: 20px;
`;

export const ToDektopButton = styled.div`
  position: absolute;
  bottom: 30px;
  right: -10px;
  border-radius: 20px 3px 3px 3px;
  width: 160px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5793FF;
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    box-shadow: inset 0 0 0 2px #8c86ff;
    > span {
      transform: scale(1.05);
    }
  }
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-align: center;
      padding-right: 15px
  }
`;
