import axios from 'axios';

// TODO @MART - Delete on EOL Branch

// DEV : 'http://localhost:8000';
// STAGING : 'https://staging.api.cypherverse.io';
// PROD : 'https://api.cypherverse.io';
const baseUrl = process.env.REACT_APP_CYPHER_API_BASE_URL;
const voxoDeusClaimPath = 'api/claim/collections/voxodeus';

const getRewardsForAddress = async (userId, override = null) => {
  if (override) {
    return await axios.get(
        `${baseUrl}/${voxoDeusClaimPath}/user/${userId}/rewards?testaddress=${override}`);
  }
  return await axios.get(
      `${baseUrl}/${voxoDeusClaimPath}/user/${userId}/rewards`);
};

const postWithdrawAll = async (data, override = null) => {
  if (override) {
    return await axios.post(
        `${baseUrl}/${voxoDeusClaimPath}/rewards/withdraw?testaddress=${override}`,
        data
    );
  }
  return await axios.post(
      `${baseUrl}/${voxoDeusClaimPath}/rewards/withdraw`,
      data
  );
};

const postClaim = async (data, override = null) => {
  if (override) {
    return await axios.post(
        `${baseUrl}/${voxoDeusClaimPath}/rewards/claim?testaddress=${override}`,
        data
    );
  }
  return await axios.post(
      `${baseUrl}/${voxoDeusClaimPath}/rewards/claim`,
      data
  );
};

const deleteWithdrawAll = async (data, override = null) => {
  const headers = {}
  if (override) {
    return await axios.delete(
        `${baseUrl}/${voxoDeusClaimPath}/rewards/withdraw?testaddress=${override}`,
        {headers, data}
    );
  }
  return await axios.delete(
      `${baseUrl}/${voxoDeusClaimPath}/rewards/withdraw`,
      {headers, data}
  );
};

export {getRewardsForAddress, postWithdrawAll, postClaim, deleteWithdrawAll};
