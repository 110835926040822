import React, { useEffect, useState } from 'react';
import { useMetamask } from 'use-metamask';
import NoVoxosOwned from './NoVoxosOwned/NoVoxosOwned';
import MyCollectionGalleryHeader from './MyCollectionGalleryHeader';
import GalleryPage from './VoxoGallery';
import { LoadingGalleryState } from './VoxoGallery/VoxoGallery/LoadingState';
import { getUserName } from 'services/openSeaService';
import { textTrunc } from 'utils/textFormat';
import { getVoxosForAddress, getVoxosWithRanking } from 'services/voxosService';
import { useWeb3React } from '@web3-react/core';

const MyCollectionGallery = () => {
  const { active, chainId, account } = useWeb3React();
  const { metaState } = useMetamask();
  const [collectionData, setCollectionData] = useState({ voxosOwned: [], voxosMinted: 0, voxoIds: [] });
  const [loading, toggleLoading] = useState(true);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (active && chainId === 1) {
      toggleLoading(true);
      setCollectionData({ voxosOwned: [], voxosMinted: 0, voxoIds: [] });
      const userId = account;
      // const userId = metaState.account[0];
      // const userId = '0x9be96a6e861d2e5aff1fe0738bed664b6f0b543e';

      // fetch user name from OpenSea
      getUserName(userId)
        .then(data => {
          const {
            data: {
              data: { user },
            },
          } = data;
          setUserName(user ? user.username : textTrunc(userId));
        })
        .catch(err => setUserName(textTrunc(userId)));

      // fetch user's voxos
      getVoxosForAddress(userId)
        .then(data => {
          const {
            data: { current_collection, mint_count },
          } = data;
          const voxoIds = current_collection.reduce((out, d) => [...out, d.token_id], []);
          fetchNextBatch(voxoIds, mint_count);
        })
        .catch(err => console.log(err));
    }
  }, [metaState.account]);

  const fetchNextBatch = (voxoIds, minted) => {
    getVoxosWithRanking(voxoIds || collectionData.voxoIds, collectionData.voxosOwned.length).then(
      ({ data: { result } }) => {
        setCollectionData({
          ...collectionData,
          voxosOwned: [...collectionData.voxosOwned, ...result],
          voxoIds: voxoIds ? voxoIds : collectionData.voxoIds,
          voxosMinted: minted ? minted : collectionData.voxosMinted,
        });
        toggleLoading(false);
      },
    );
  };

  return (
    <div style={{ minHeight: `calc(100vh - 298px)` }}>
      <MyCollectionGalleryHeader collectionData={collectionData} account={account} userName={userName} />
      {loading ? (
        <LoadingGalleryState />
      ) : (
        <>
          {collectionData.voxosOwned.length ? (
            <GalleryPage
              voxosArr={collectionData.voxosOwned}
              fetchNextBatch={fetchNextBatch}
              totalVoxos={collectionData.voxoIds.length}
            />
          ) : (
            <NoVoxosOwned />
          )}
        </>
      )}
    </div>
  );
};

export default MyCollectionGallery;
