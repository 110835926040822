import React from 'react';
import { EXTERNAL_URLS } from '../../../utils/constants';

const ConnectToDownload = () => {
  return (
    <>
      <p>To view your collection and download your Voxo assets,</p>
      <p>please login to your Metamask wallet (Ethereum mainnet).</p>
      <br />
      <p>
        By connecting, you agree to our{' '}
        <a href={EXTERNAL_URLS.TCV_TERMS}  target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={EXTERNAL_URLS.TCV_PRIVACY}  target="_blank">
          Privacy Policy
        </a>
        .
      </p>
    </>
  );
};

export default ConnectToDownload;
