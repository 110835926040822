import styled from 'styled-components';
import { VoxColors } from 'themes/Colors';

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  text-shadow: 0 4px 25px rgba(215, 89, 123, 0.4);
  font-family: Outage, sans-serif;
  color: #fffff;
`;

export const SubTitle = styled.div`
  margin-top: 40px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 180%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${VoxColors.tron4};
`;

export const AddressWrapper = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 180%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${VoxColors.tron7};
`;

export const Address = styled.div`
  background-color: #0B1528;
  border-radius: 100px;
  width: 200px;
  height: 32px;
  margin: auto;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${VoxColors.tron7};
  padding: 0 20px
`;


export const WalletWrapperGrid = styled.div`
  position: relative;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  width: 269px;
  column-gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

export const AccountWallet = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;

  button {
    background-color: ${VoxColors.tron1};;
    border-radius: 100px;
    height: 32px;
    color: ${VoxColors.tron7};
    text-transform: uppercase;
    padding: 0 20px;
    outline: none;
    border-color: none;
    border-style: none;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 10%;
    position: relative;
    cursor: pointer;
    font-family: 'IBM Plex Sans', sans-serif;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    top: 14px;
    transition: all 0.2s ease-in;

    &:after {
      display: none;
      position: absolute;
      top: 35px;
      content: 'Click to copy address';
      color: #ddd;
      font-size: 12px;
      white-space: nowrap;
      transition: all 0.5s ease-out;
      background: rgba(42, 64, 96, 0.5);
      box-shadow: 1px 2px 80px rgba(11, 21, 40, 0.5);
      border-radius: 2px;
      padding: 7px 10px;
      font-family: 'IBM Plex Sans', sans-serif;
      letter-spacing: 0.01em;
    }
    &:hover {
      color: #ffffff;
      &:after {
        display: block;
      }
    }
    &:focus {
      outline: none;
      &:after {
        content: 'Copied!';
        color: #56f6ce;
        font-weight: bold;
      }
    }
  }
`;

export const VerticalLine = styled(AccountWallet)`
  font-weight: 500;
  line-height: 20px;
  color: ${VoxColors.white};
`;

export const DisconnectLink = styled(AccountWallet)`
  font-weight: 700;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const VoxosNumbersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  max-width: 1200px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding-left: 78px;
  padding-right: 88px;
`;

export const VoxosNumbersBlock = styled.div`
  background-color: ${VoxColors.tron1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 220px;
  border-radius: 3px;
  margin: 0 7.5px;
`;

export const VoxosNumbersTitle = styled.div`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: #bdb9dd;
  letter-spacing: 0.1em;
  opacity: 0.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const VoxosNumbers = styled.div`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: ${VoxColors.matrixNeon};
  padding-top: 10px;
`;
