import React from 'react';
import { RewardsWrapper, ActivityClaimData, ScrollButtonContainer, ScrollTopButton } from './activityClaim.styled';
import ActivityClaim from './ActivityClaim';

const ActivityClaimPage = ({ rewardsData, postWithdrawAll, postClaim, updateRewardsData, updateRewardsSingleActivityData, deleteWithdrawAll }) => {
  return (
    <RewardsWrapper>
      <ActivityClaimData>
        {rewardsData ? (
          <ActivityClaim rewardsData={rewardsData} postWithdrawAll={postWithdrawAll} deleteWithdrawAll={deleteWithdrawAll}
                         postClaim={postClaim}
                         updateRewardsData={updateRewardsData}
                         updateRewardsSingleActivityData={updateRewardsSingleActivityData}/>
        ) : null}
        <ScrollButtonContainer>
          <ScrollTopButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </ScrollButtonContainer>
      </ActivityClaimData>
    </RewardsWrapper>
  );
};
export default ActivityClaimPage;
