import React from "react";
import ResetIcon from "assets/icons/tail-spin.svg";
import { LoadingWrapper } from "../gallery.styled";

export const LoadingGalleryState = () => {
  return (
    <LoadingWrapper>
      <img src={ResetIcon} alt="loading" />
      Loading...
    </LoadingWrapper>
  );
};
