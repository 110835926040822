import {
  LoadingText,
  VoxoGalleryWrapper,
  VoxoGridItem,
  VoxoGridItemArchetype,
  VoxoGridItemImage,
  VoxoGridItemImageContainer,
  VoxoGridItemInfo,
  VoxosGrid,
  VoxosGridBottomLine,
} from 'containers/GalleryPage/gallery.styled';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { padLeadingZeros } from 'utils/numberFormat';

const VoxoGallery = ({ voxos, showMoreData, hasMore, showFilters }) => {
  return (
    <VoxoGalleryWrapper showFilters={showFilters}>
      <InfiniteScroll
        dataLength={voxos.length}
        next={showMoreData}
        hasMore={hasMore}
        loader={hasMore ? <LoadingText>Loading...</LoadingText> : null}
      >
        <VoxosGrid>
          {voxos.map((item, i) => (
            <VoxoGridItem key={i}>
              <NavLink to={`/voxo/${item.token_id}`}>
                <VoxoGridItemImageContainer>
                  <VoxoGridItemImage src={item.image} />
                </VoxoGridItemImageContainer>
                <VoxoGridItemInfo>
                  Voxo #{padLeadingZeros(item.token_id, 4)}{' '}
                  <span>
                    Rank <span>{item.rarity.rank}</span>
                  </span>
                </VoxoGridItemInfo>
                <VoxoGridItemArchetype>{item.traits['#4 Archetype']}</VoxoGridItemArchetype>
              </NavLink>
            </VoxoGridItem>
          ))}
          {hasMore && <VoxosGridBottomLine />}
        </VoxosGrid>
      </InfiniteScroll>
    </VoxoGalleryWrapper>
  );
};
export default VoxoGallery;