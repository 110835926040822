import React, { useState } from 'react';
import { ConnectMetamaskModal, ConnectMetamaskModalBody, ButtonsContainer, TextBlock } from './dialoguesContex.styled';
import ConnectToDownload from './ConnectToDownload';
import SwitchToMainnet from './SwitchToMainnet';
import { ColoredButton } from '../myCollectionPage.styled';
import { useWeb3React } from '@web3-react/core';
import SelectConnector from './SelectConnector';

const SwitchText = ({ isConnected, chainId, error }) => {
  if ((chainId && chainId !== 1) || (error && error.name === 'UnsupportedChainIdError')) return <SwitchToMainnet />;
  if (!isConnected) return <ConnectToDownload />;
  return null;
};

const DialoguesContext = ({ isConnected }) => {
  const { error, chainId } = useWeb3React();

  const [showSelectConnectorPage, toggleShowSelectConnectorPage] = useState(false);

  return (
    <div style={{ minHeight: `calc(100vh - 348px)` }}>
      <ConnectMetamaskModal>
        <ConnectMetamaskModalBody>
          {showSelectConnectorPage ? (
            <SelectConnector toggleShowSelectConnectorPage={toggleShowSelectConnectorPage} />
          ) : (
            <>
              <TextBlock>
                <SwitchText isConnected={isConnected} chainId={chainId} error={error} />
              </TextBlock>
              {(chainId && chainId !== 1) || (error && error.name === 'UnsupportedChainIdError') ? null : (
                <ButtonsContainer>
                  <ColoredButton onClick={() => toggleShowSelectConnectorPage(true)}>
                    <span>Connect Wallet</span>
                  </ColoredButton>
                </ButtonsContainer>
              )}
            </>
          )}
        </ConnectMetamaskModalBody>
      </ConnectMetamaskModal>
    </div>
  );
};

export default DialoguesContext;
