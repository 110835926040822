import React, { useMemo } from 'react';
import { CollectionPageStyle, Header } from './myCollectionPage.styled';
import { useMetamask } from 'use-metamask';
import Footer from 'components/footer';
import { getChainId } from '../../utils/helper';
import DialoguesContext from './Dialogues/DialoguesContext';
import MyCollectionGallery from './MyCollectionGallery';
import { useWeb3React } from '@web3-react/core';

const MyCollectionPage = () => {
  const { active, chainId } = useWeb3React();
  const showPage = useMemo(() => active && (chainId === 1 || chainId === 4), [active, chainId]);

  return (
    <CollectionPageStyle>
      {showPage ? <MyCollectionGallery /> : <DialoguesContext isConnected={active} />}
      <Footer />
    </CollectionPageStyle>
  );
};

export default MyCollectionPage;
