import styled from 'styled-components';
import * as font from 'assets/CSSPalette/fonts';
import * as color from 'assets/CSSPalette/colors';
import CHECK from 'assets/icons/check_24px.svg';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : 'unset')};
`;

export const SelectBox = styled.div`
  border: 1px solid rgb(255 255 255 / 20%);
  filter: drop-shadow(0px 1px 10px rgba(140, 134, 255, 0.5));
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
export const SelectValue = styled.div`
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  fill: #fff;
`;
export const ArrowDown = styled.img`
  position: absolute;
  right: 10px;
`;
export const SelectList = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(255 255 255 / 20%);
  position: absolute;
  width: 100%;
  top: 41px;
  background: #ffffff;
  box-shadow: 0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5);
  border-radius: 0px 0px 5px 5px;
  z-index: 2;
`;
export const ListItem = styled.div`
  padding: 8px 16px;
  text-align: right;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? font.WEIGHT_BOLD : 500)};
  color: ${({ active }) => (active ? color.TRON_1 : color.TRON_2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  fill: #5d7195;
  color: #5d7195;
  &:hover {
    font-weight: ${font.WEIGHT_BOLD};
    fill: #0e1c34;
    color: #0e1c34;
    background: rgba(11, 21, 40, 0.1);
  }
  transition: all 0.2s ease-in;
  &:after {
    content: url(${({ active }) => (active ? CHECK : '')});
  }
`;
