import styled from 'styled-components';

export const FilterSelectItem = styled.span`
  display: flex;
  align-items: center;
  > svg {
    margin-left: 8px;
    transform: ${({ asc }) => (asc ? 'unset' : `rotate(180deg)`)};
  }
`;
