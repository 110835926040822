import React from 'react';
import {
  TextBlock,
  ConnectMetamaskTextHeader,
} from './dialoguesContex.styled';
import { EXTERNAL_URLS } from '../../../utils/constants';

const ConnectToSeeRewards = () => {
  return (
    <>
      <ConnectMetamaskTextHeader>
        Connect Wallet
      </ConnectMetamaskTextHeader>
      <TextBlock>
        <p>To continue claiming your minter rewards,</p>
        <p>please login to your wallet on Ethereum mainnet via desktop.</p>
        <br />
        <p>
          By connecting, you agree to our<br />
          <a href={EXTERNAL_URLS.TCV_TERMS}
             target='_blank' rel='noreferrer'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={EXTERNAL_URLS.TCV_PRIVACY}
             target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
          .
        </p>
      </TextBlock>
    </>
  );
};

export default ConnectToSeeRewards;
