import React from 'react';
import {
  Title,
  SubTitle,
  AddressWrapper,
  Address,
  VoxosNumbersWrapper,
  VoxosNumbersTitle,
  VoxosNumbers,
  VoxosNumbersBlock,
  AccountWallet
} from './myRewardsHeader.styled';

import {accountTrunc} from 'utils/textFormat';
import CopyToClipboard from "react-copy-to-clipboard";

const MyRewardsHeader = ({rewardsData, account}) => {
  const {voxos_minted_no, voxos_owned_no, rewards_claimed, rewards_withdrawn, os_username} = rewardsData;

  return (
      <>
        <Title>Rewards</Title>
        <SubTitle>Welcome</SubTitle>
        <AccountWallet>
            <CopyToClipboard text={account}>
              <button>{os_username} {os_username ? '|' : ''} {accountTrunc(account)}</button>
            </CopyToClipboard>
        </AccountWallet>
        <VoxosNumbersWrapper>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>VOXOS MINTED</VoxosNumbersTitle>
            <VoxosNumbers>{voxos_minted_no}</VoxosNumbers>
          </VoxosNumbersBlock>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>VOXOS OWNED</VoxosNumbersTitle>
            <VoxosNumbers>{voxos_owned_no}</VoxosNumbers>
          </VoxosNumbersBlock>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>REWARDS CLAIMED</VoxosNumbersTitle>
            <VoxosNumbers>{rewards_claimed ? Number(rewards_claimed).toFixed(4) : '0.000'} ETH</VoxosNumbers>
          </VoxosNumbersBlock>
          <VoxosNumbersBlock>
            <VoxosNumbersTitle>REWARDS WITHDRAWN</VoxosNumbersTitle>
            <VoxosNumbers>{rewards_withdrawn ? Number(rewards_withdrawn).toFixed(4) : '0.000'} ETH</VoxosNumbers>
          </VoxosNumbersBlock>
        </VoxosNumbersWrapper>
      </>
  );
};

export default MyRewardsHeader;
