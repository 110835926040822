import React, {useState} from 'react';
import {
  ActivityGrid,
  BalanceGrid,
  RewardsInnerWrapper,
  ActivityGridItem,
  ActivityGridItemHeader,
  ActivityGridItemStatus,
  ActivityGridItemMintedHeld,
  ActivityGridItemSeparator,
  ActivityHeader,
  ActivitySubHeader,
  ActivityGridItemIcon,
  ActivityGridItemBg,
  ClaimButton,
  ClaimButtonInactive,
  BalanceHeader,
  AvailableBalance,
  WithdrawAllButton,
  WithdrawAllText,
  WhyWaitText,
  AvailableBalanceText,
  AvailableBalanceValueText,
  AvailableBalanceExpiresText,
  FAQBox,
  WithdrawAllButtonInactive,
  CancelButton,
  CancelButtonInactive
} from '../activityClaim.styled';
import {NavLink, useLocation} from 'react-router-dom';
import ClaimModal from './ClaimModal';
import {toDateString} from "../../../../../utils/timeProcessors";
import CheckedIcon from 'assets/icons/check_24px.png';
import FaqIcon from 'assets/icons/faq.png';
import FaqIconHover from 'assets/icons/faq_hover.png';
import ChevronIcon from 'assets/icons/chevron_faq.png';
import ChevronIconHover from 'assets/icons/chevron.png';
import AccessTimeIcon from 'assets/icons/access_time_24px.png';
import ExclamationIcon from 'assets/icons/error_outline_24px.png';
import ReactCardFlip from 'react-card-flip';

import * as color from "../../../../../assets/CSSPalette/colors";
import {useWeb3React} from "@web3-react/core";
import { EXTERNAL_URLS } from '../../../../../utils/constants';

const ActivityClaim = ({
                         rewardsData,
                         postWithdrawAll,
                         postClaim,
                         updateRewardsData,
                         updateRewardsSingleActivityData,
                         deleteWithdrawAll
                       }) => {
  const [showClaimModal, toggleShowClaimModal] = useState(false);
  const {library, account: accPre} = useWeb3React();
  const account = accPre.toLowerCase();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('testaddress');

  const [modalCountdownStart, setModalCountdownStart] = useState(false);

  const [claimErrorState, setClaimErrorState] = useState(false);
  const [withdrawAllErrorState, setWithdrawAllErrorState] = useState(false);
  const [cancelWithdrawAllErrorState, setCancelWithdrawAllErrorState] = useState(false);
  const [cancelInProgressState, setCancelInProgressState] = useState(false);

  const [faqHover, setFaqHover] = useState(false);

  const withdrawAllInner = async () => {
    setWithdrawAllErrorState(false);
    const message = JSON.stringify({
      instruction: 'Schedule the full withdrawal of my rewards balance',
      to_address: account,
      balance: rewardsData.available_balance,
      send_on: rewardsData.next_scheduled_transfer,
      signed_on: new Date().toISOString()
    });
    // this will pop up metamask ask user to sign the message
    const signature = await library.send('personal_sign', [message, account.toLowerCase()]);
    const data = {signature, message, account};
    postWithdrawAll(data, name || null)
        .then(result => {
          updateRewardsData(result.data)
        })
        .catch(() => {
          setWithdrawAllErrorState(true);
        });
  };

  const claimInner = async (message) => {
    setClaimErrorState(false);
    // this will pop up metamask ask user to sign the message
    const signature = await library.send('personal_sign', [JSON.stringify(message), account.toLowerCase()]);
    const data = {signature, message: JSON.stringify(message), account};
    postClaim(data, name || null)
        .then(result => {
          setModalCountdownStart(true);
          setTimeout(() => {
            toggleShowClaimModal(false);
            updateRewardsData(result.data);
            setModalCountdownStart(false);
          }, 6000);
        })
        .catch(() => {
          toggleShowClaimModal(false);
          setClaimErrorState(true);
        });
  };

  const deleteWithdrawAllInner = async () => {
    if (cancelInProgressState) return;
    setCancelWithdrawAllErrorState(false);
    setCancelInProgressState(true);
    const message = JSON.stringify({
      instruction: 'Cancel the full withdrawal of my rewards balance',
      to_address: account,
      balance: 0,
      send_on: rewardsData.next_scheduled_transfer,
      signed_on: new Date().toISOString()
    });
    // this will pop up metamask ask user to sign the message
    const signature = await library.send('personal_sign', [message, account.toLowerCase()]);
    const data = {signature, message, account};
    deleteWithdrawAll(data, name || null)
        .then(result => {
          updateRewardsData(result.data);
          setCancelInProgressState(false);
        })
        .catch(() => {
          setCancelWithdrawAllErrorState(true);
          setCancelInProgressState(false);
        });
  };

  const handleRewardStatus = (type, status, is_expired) => {
    if (type === 'withdrawal') {
      switch (status) {
        case 'scheduled':
          return {
            color: '#5793FF',
            text: 'Withdrawal scheduled',
            icon: cancelWithdrawAllErrorState ? <CancelButtonInactive><span>Failed</span></CancelButtonInactive>
                :
                <CancelButton onClick={() => deleteWithdrawAllInner()}><span>{cancelInProgressState ? 'Canceling' : 'Cancel'}</span></CancelButton>
          };
        case 'sent_to_user':
          return {color: '#56F6CE', text: 'Withdrawal', icon: <img src={CheckedIcon} alt="CheckedIcon"/>};
        case 'expired':
        case 'sent_to_treasury':
          return {color: '#BDB9DD', text: 'Balance expired', icon: <img src={ExclamationIcon} alt="ExclamationIcon"/>};
        case 'failed':
          return {color: '#BDB9DD', text: 'Withdrawal failed', icon: <img src={CheckedIcon} alt="CheckedIcon"/>};
        case 'unavailable':
          return {
            color: '#0E1C34',
            text: 'No rewards available for claiming',
            icon: null
          };
        default:
          return {color: '#BDB9DD', text: 'Status not found', icon: <img src={ExclamationIcon} alt="ExclamationIcon"/>};
      }
    } else {
      switch (status) {
        case 'unclaimed':
          return {
            color: '#0E1C34',
            text: 'Rewards available for claiming',
            icon: <img src={CheckedIcon} alt="CheckedIcon"/>
          };
        case 'claimed':
        case 'pending_transfer':
        case 'disbursed':
        case 'expired_balance':
        case 'reclaimed_balance':
          return {color: '#56F6CE', text: 'Rewards claimed', icon: <img src={CheckedIcon} alt="CheckedIcon"/>};
        case 'expired':
        case 'reclaimed_share':
          return {color: '#BDB9DD', text: 'Rewards Expired', icon: <img src={ExclamationIcon} alt="ExclamationIcon"/>};
        case 'unavailable':
          return {
            color: '#0E1C34',
            text: 'No rewards available for claiming',
            icon: null
          };
        default:
          return {color: '#BDB9DD', text: 'Status not found', icon: <img src={ExclamationIcon} alt="ExclamationIcon"/>};
      }
    }
  };

  const claimButtonFunc = () => claimErrorState ? (
          <ClaimButtonInactive>
            <span>Failed</span>
          </ClaimButtonInactive>) :
      <ClaimButton onClick={() => {
        toggleShowClaimModal(true);
      }}>
        <span>Claim</span>
      </ClaimButton>

  const minimumBalance = process.env.REACT_APP_ENV === 'staging' ? .00005 : .005;

  const withdrawAllButtonFunc = () => rewardsData?.available_balance <= minimumBalance || withdrawAllErrorState ?
      <WithdrawAllButtonInactive>
        {withdrawAllErrorState ? <span>Failed - Please Try Again Later</span> :
            <span>Minimum withdrawal {minimumBalance} ETH</span>}
      </WithdrawAllButtonInactive> : (
          <WithdrawAllButton onClick={() => withdrawAllInner()}>
            {withdrawAllErrorState ? <span>Failed - Please Try Again Later</span> : <span>Withdraw all</span>}
          </WithdrawAllButton>);

  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  const primaryStatuses = ['unavailable', 'unclaimed']
  const expiredStatuses = [
    'expired',
    'expired_balance',
    'reclaimed_balance',
    'reclaimed_share',
    'sent_to_treasury'
  ]
  return (
      <RewardsInnerWrapper>
        <ActivityGrid>
          <ActivityHeader>your rewards activity</ActivityHeader>
          <ActivitySubHeader>
            <p>Step 1: <span>Claim</span> your rewards to save your available quarterly rewards for withdrawal (valid
              for a year).</p>
            <p>Step 2: <span>Withdraw</span> to schedule your ETH balance to be sent to your address by the end of the
              week.</p>
          </ActivitySubHeader>
          {rewardsData && rewardsData.activity && rewardsData.activity.length > 0 && rewardsData.activity.map((item, i) => (
              <div key={i}>
                <ReactCardFlip isFlipped={primaryStatuses.includes(item.status)} flipDirection="vertical"
                               flipSpeedBackToFront={1} flipSpeedFrontToBack={1}>
                  {/* Default Activity Card */}
                  <ActivityGridItem>
                    <ActivityGridItemBg>
                      <ActivityGridItemStatus>
                        <ActivityGridItemHeader
                            style={{color: color.VOID_7}}>Q{item.quarter} {item.year}</ActivityGridItemHeader>
                        <div style={{
                          fontSize: 15,
                          display: 'inline-flex',
                          color: handleRewardStatus(item.type, item.status, item.is_expired).color,
                          opacity: expiredStatuses.includes(item.status) ? 0.5 : 1
                        }}>{handleRewardStatus(item.type, item.status).text}
                          <li style={{
                            color: color.VOXO_WHITE,
                            fontSize: 13,
                            paddingLeft: 15
                          }}>{toDateString(item.date)}</li>
                        </div>
                      </ActivityGridItemStatus>
                      <ActivityGridItemMintedHeld>
                        {item.type === 'claim' ? <ActivityGridItemHeader
                            style={{color: color.VOID_7}}>Minted ({item.minter_shares || 0})
                        </ActivityGridItemHeader> : <div style={{height: 21, width: 1}}></div>}
                        <span
                            style={{
                              opacity: expiredStatuses.includes(item.status) ? 0.5 : 1,
                              color: expiredStatuses.includes(item.status) ? color.VOID_7 : color.VOXO_WHITE
                            }}>{item.type === 'claim' ? Number(item.minter_amount).toFixed(5) : Number(item.amount).toFixed(5)} ETH</span>
                      </ActivityGridItemMintedHeld>
                      <ActivityGridItemMintedHeld>
                        {item.type === 'withdrawal' ? <ActivityGridItemHeader
                            style={{color: color.VOID_7}}>TXN
                        </ActivityGridItemHeader> : <ActivityGridItemHeader
                            style={{color: color.VOID_7}}>Held ({item.holder_shares || 0})
                        </ActivityGridItemHeader>}
                        {item.type === 'withdrawal' && item.tx_hash && item.status !== 'sent_to_treasury' ?
                            <NavLink style={{
                              fontSize: 15,
                              fontWeight: 400,
                              color: color.VOID_NEON_,
                              textDecoration: 'underline'
                            }}
                                     to={{pathname: `https://etherscan.io/tx/${item.tx_hash}`}} target="_blank"
                                     rel="noreferrer">
                              Etherscan >
                            </NavLink> : <span
                                style={{
                                  color: color.VOXO_WHITE,
                                  opacity: expiredStatuses.includes(item.status) ? 0.5 : 1
                                }}>{item.holder_amount ? `${Number(item.holder_amount).toFixed(5)} ETH` : '-'}</span>}
                      </ActivityGridItemMintedHeld>
                      <ActivityGridItemIcon>
                        {handleRewardStatus(item.type, item.status).icon}
                      </ActivityGridItemIcon>
                    </ActivityGridItemBg>
                  </ActivityGridItem>

                  {/* Primary Activity Card */}
                  <ActivityGridItem>
                    <ActivityGridItemBg style={{
                      backgroundColor: 'white',
                      border: '1px solid #2A4060'
                    }}>
                      <ActivityGridItemStatus>
                        <ActivityGridItemHeader
                            style={{color: color.TRON_2}}>Q{item.quarter} {item.year}</ActivityGridItemHeader>
                        <div style={{
                          fontSize: 15,
                          display: 'inline-flex',
                          color: handleRewardStatus(item.type, item.status, item.is_expired).color,
                          opacity: item.status === 'unclaimed' ? 0.5 : 1,
                          fontWeight: item.status === 'unclaimed' ? 500 : 600
                        }}>{handleRewardStatus(item.type, item.status).text}
                          {item.status === 'unclaimed' ?
                              <li style={{fontSize: 13, paddingLeft: 15}}>{toDateString(item.date)}</li> : null}
                        </div>
                      </ActivityGridItemStatus>
                      <ActivityGridItemMintedHeld>
                        {item.type === 'withdrawal' ?
                            <div style={{height: 21, width: 1}}></div> : <ActivityGridItemHeader
                                style={{color: color.TRON_2}}>Minted
                              ({item.minter_shares || 0})</ActivityGridItemHeader>}
                        <span
                            style={{color: color.TRON_1}}>{item.type === 'withdrawal' ? `${Number(item.amount).toFixed(5)} ETH` : item.status === 'unclaimed' ? `${Number(item.minter_amount).toFixed(5)} ETH` : '--'}</span>
                      </ActivityGridItemMintedHeld>
                      <ActivityGridItemMintedHeld>
                        {item.type === 'withdrawal' ? <ActivityGridItemHeader
                                style={{color: color.TRON_2}}>TXN</ActivityGridItemHeader> :
                            <ActivityGridItemHeader
                                style={{color: color.TRON_2}}>Held
                              ({item.holder_shares || 0})</ActivityGridItemHeader>}
                        {item.type === 'withdrawal' && item.tx_hash && item.status !== 'sent_to_treasury' ?
                            <NavLink style={{fontSize: 15, fontWeight: 400, color: color.VOID_NEON}}
                                     to={{pathname: `https://etherscan.io/tx/${item.tx_hash}`}} target="_blank"
                                     rel="noreferrer">
                              Etherscan >
                            </NavLink> : <span
                                style={{color: color.TRON_1}}>{item.holder_amount ? `${Number(item.holder_amount).toFixed(5)} ETH` : '--'}</span>}
                      </ActivityGridItemMintedHeld>
                      <ActivityGridItemIcon>
                        {item.status === 'unclaimed' ? claimButtonFunc(item, i) : null}
                      </ActivityGridItemIcon>
                    </ActivityGridItemBg>
                  </ActivityGridItem>
                </ReactCardFlip>
                {rewardsData.activity.length - 1 !== i ? <ActivityGridItemSeparator/> : null}
              </div>
          ))}
          {/* Show an empty card for users which have neither any previous activities, nor eligibility in the current quarter */}
          {rewardsData && rewardsData.activity && rewardsData.activity.length === 0 && (
              <ActivityGridItem>
                <ActivityGridItemBg style={{background: '#16243E', borderRadius: 10}}>
                  <ActivityGridItemStatus>
                    <ActivityGridItemHeader
                        style={{color: color.VOID_7}}>Q{quarter} {new Date().getFullYear()}</ActivityGridItemHeader>
                    <div style={{
                      fontSize: 15,
                      display: 'inline-flex',
                      color: '#BDB9DD',
                      opacity: 0.5
                    }}>No rewards available for claiming
                    </div>
                  </ActivityGridItemStatus>
                  <ActivityGridItemMintedHeld>
                    <ActivityGridItemHeader
                        style={{color: color.VOID_7}}>Minted (0)</ActivityGridItemHeader>
                    <span
                        style={{color: color.VOID_7, opacity: 0.5}}>--</span>
                  </ActivityGridItemMintedHeld>
                  <ActivityGridItemMintedHeld>
                    <ActivityGridItemHeader
                        style={{color: color.VOID_7}}>Held (0)</ActivityGridItemHeader>
                    <span
                        style={{color: color.VOID_7}}>--</span>
                  </ActivityGridItemMintedHeld>
                  <ActivityGridItemIcon style={{paddingTop: 0}}>
                    <img src={ExclamationIcon} alt="ExclamationIcon"/>
                  </ActivityGridItemIcon>
                </ActivityGridItemBg>
              </ActivityGridItem>
          )}
        </ActivityGrid>
        <BalanceGrid>
          <BalanceHeader>
            Your Balance
          </BalanceHeader>
          <AvailableBalance style={{backgroundColor: rewardsData.available_balance ? color.VOXO_WHITE : color.TRON_2}}>
            <AvailableBalanceText
                style={{marginTop: 30, color: rewardsData.available_balance ? color.TRON_2 : color.TRON_6}}>available
              balance</AvailableBalanceText>
            <AvailableBalanceValueText
                style={{marginTop: 6, color: rewardsData.available_balance ? color.TRON_1 : color.VOXO_WHITE}}
            >{Number(rewardsData.available_balance).toFixed(5)} ETH</AvailableBalanceValueText>
            <AvailableBalanceExpiresText style={{marginTop: 10}}>Expires <span
                style={{marginLeft: 4}}>{toDateString(rewardsData.expiry_date, 'short', 'utc')}</span></AvailableBalanceExpiresText>
          </AvailableBalance>
          {withdrawAllButtonFunc()}
          <WithdrawAllText style={{margin: '31px auto 0 auto'}}>You receive your balance, minus your share in gas
            fees</WithdrawAllText>
          <WithdrawAllText>Next batch transfer on <span
              style={{marginLeft: 4}}>{toDateString(rewardsData.next_scheduled_transfer, 'short', 'utc')}</span></WithdrawAllText>
          <WhyWaitText
              onClick={() => window.open(EXTERNAL_URLS.REWARDS_FAQ_WITHDRAWAL, '_blank')}>Why
            the wait?</WhyWaitText>
          <FAQBox
              onMouseEnter={() => setFaqHover(true)}
              onMouseLeave={() => setFaqHover(false)}
              onClick={() => window.open(EXTERNAL_URLS.REWARDS_FAQ, '_blank')}>
            <div>
              <img src={faqHover ? FaqIconHover : FaqIcon} alt="FaqIcon" style={{maxWidth: 36, maxHeight: 33, marginTop: 5}}/>
              <div>
                <p>Questions?</p>
                <p><span>FAQ</span></p>
              </div>
            </div>
            <img src={faqHover ? ChevronIconHover : ChevronIcon} alt="ChevronIcon"/>
          </FAQBox>
        </BalanceGrid>
        {showClaimModal &&
            <ClaimModal
                modalCountdownStart={modalCountdownStart}
                closeModal={() => {
                  toggleShowClaimModal(false);
                }
                } claimInner={claimInner}/>}

      </RewardsInnerWrapper>
  );
};
export default ActivityClaim;
