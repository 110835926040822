import styled from 'styled-components';
import * as color from 'assets/CSSPalette/colors';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(14, 28, 52, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Modal = styled.div`
  width: 821px;
  height: 834px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 5px 61px rgba(225, 119, 144, 0.1);
  border-radius: 25px;
  background: #fff;
  color: #fff;
  opacity: 1;
`;
export const ModalBody = styled.div`
  padding: 50px 45px 40px 45px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const ModalHeader = styled.div`
  font-size: 28px;
  background: ${color.TRON_3};
  border-radius: 20px 20px 0 0 ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  font-family: 'Outage';
  width: 100%;
  font-weight: 700;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  position: relative;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
`;

export const ClaimButton = styled.div`
  margin: 0 auto 20px auto;
  border-radius: 10px 2px 10px 2px;
  width: 582px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    background: linear-gradient(266.38deg, #cc3767 0%, #ab5fb4 49.18%, #8c86ff 100%);
    box-shadow: inset 0 0 0 2px #8c86ff;
    > span {
      transition: transform 0.2s ease-out, filter 0.2s ease-out;
      transform: scale(1.05);
    }
  }
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
  }
`;

export const ClaimButtonInactive = styled.div`
  margin: 0 auto 20px auto;
  border-radius: 10px 2px 10px 2px;
  width: 582px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.TRON_7};
  cursor: pointer;
  transition: all 0.1s ease-out;
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
  }
`;
export const NpsContainerBlock = styled.div`
  margin: 0 auto;
  width: 588px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NpsButtonBlock = styled.div`
  margin: 0 auto;
  border-radius: 5px;
  width: 50px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #B3C3E2;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const TextBlockMedium = styled.div`
  color: ${color.TRON_1};
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  justify-content: start;
  display: flex;
  opacity: 0.9;
  margin-bottom: 30px;
  > span {
    position: absolute;
    top: 0;
    left: 0;
    fill: #fff;
  }
`;
export const TextBlockSmall = styled.div`
  width: 821px;
  color: ${color.TRON_1};
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  justify-content: start;
  display: flex;
  opacity: 0.9;
  padding: 0 119px;
  > ul {
  padding: 0 0 0 13px;
  margin: 0;
  list-style-position: outside;
  > li {
  padding: 0;
  margin: 0;
  > a {
  font-weight: 700;
  }
  }
  }
`;
export const TextBlockLarger = styled.div`
  width: 821px;
  color: ${color.TRON_1};
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  justify-content: start;
  display: flex;
  opacity: 0.9;
  padding: 0 119px;
  margin-bottom: 17px;
`;
export const TextBlockThankYou = styled.div`
  color: ${color.TRON_1};
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  justify-content: start;
  display: flex;
  opacity: 0.9;
  > span {
    margin-left: 8px;
    margin-right: 2px;
    color: ${color.VOID_NEON}; 
  }
`;
export const TextareaBlock = styled.textarea`
  width: 583px;
  height: 79px;
  color: ${color.TRON_2};
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  padding: 15px 24px;
  border: 1px solid #B3C3E2;
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
  margin-bottom: 21px;
`;
export const CloseModalButton = styled.div`
  position: absolute;
  right: 106px;
  top: 65px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  transition: all 0.15s ease-out;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: #fff;
  }
  &:hover {
    color: #ff6a84;
  }
`;
export const UnlikelyExtremelyLikelyBlock = styled.div`
  margin: 0 auto;
  width: 580px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${color.TRON_1};
  margin-top: 6px;
`;
