import styled from 'styled-components';
import * as color from 'assets/CSSPalette/colors';

export const ConnectMetamaskModal = styled.div`
  width: ${({ smallImages }) => (smallImages ? '600px' : '800px')};
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  color: #fff;
  margin: 0 auto;
  background: rgba(93, 113, 149, 0.1);
  border: 1px solid rgb(204, 217, 242, 0.1);
  margin-top: 50px;
`;
export const ConnectMetamaskModalBody = styled.div`
  padding: 50px 45px 40px 45px;
  height: 340px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border-radius: 30px;
`;
export const TextBlock = styled.div`
  width: 440px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  opacity: 0.9;
  p {
    margin: 0;
    color: #ffffff;
  }
  a {
    color: ${color.VOID_NEON};
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 15px;
  width: 100%;
`;

export const ConnectorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 60px;
  width: 600px;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ smallImages }) => (smallImages ? '60px' : '100px')};
  height: ${({ smallImages }) => (smallImages ? '60px' : '100px')};
  background: #242d3e;
  z-index: 10;
  /* transition: all 0.3s ease-in; */
  img {
    width: ${({ smallImages }) => (smallImages ? '55px' : '57px')};
    transition: all 0.3s ease-out;
  }
`;
export const ConnectorType = styled.div`
  font-weight: ${({ smallImages }) => (smallImages ? '500' : '700')};
  color: #fff;
  margin-top: 20px;
  transition: all 0.3s ease-in;
`;
export const Connector = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: ${({ smallImages }) => (smallImages ? '55px' : '100px')};
  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: ${({ smallImages }) => (smallImages ? '65px' : '108px')};
    height: ${({ smallImages }) => (smallImages ? '65px' : '108px')};
    background: linear-gradient(86.26deg, #8c86ff 3.09%, #ab5fb4 50.76%, #cc3767 100%);
    left: ${({ smallImages }) => (smallImages ? '-5px' : '-4px')};
    top: ${({ smallImages }) => (smallImages ? '-2px' : '-4px')};
    transition: all 0.3s ease-out;
    opacity: 0;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
    ${ConnectorType} {
      color: #ff6a84;
    }
    ${LogoContainer} {
      background: #293e5c;
    }
    &::before {
      opacity: 1;
    }
  }
`;
