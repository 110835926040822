import React, { useContext, useEffect, useMemo, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Overlay,
  ContinueButton,
  ButtonsContainer,
  TextBlock,
  Files,
  FilesList,
  FilesListHeader,
  File,
  Timer,
  ClickToRefresh,
  VoxoId,
  SignMessage,
  CloseModalButton,
} from './download.styled';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon_small.svg';
import axios from 'axios';
import { AppContext } from 'context/AppContext';
import { ReactComponent as CLOSE_ICON_ICON } from 'assets/icons/close-button-icon.svg';
import { padLeadingZeros } from 'utils/numberFormat';
import { useWeb3React } from '@web3-react/core';

const initialTime = 5 * 60 * 1000; // initial time in milliseconds, defaults to 60000
// const initialTime = 3000; // initial time in milliseconds, defaults to 60000
const interval = 1000; // interval to change remaining time amount, defaults to 1000

const DownloadModal = ({ setSelectedVoxo, selectedVoxo }) => {
  const { library, account: accPre } = useWeb3React();
  const { metaState } = useContext(AppContext);
  const account = accPre?.toLowerCase();
  const [allDataLoaded, toggleAllDataLoaded] = useState(false);
  const [timer, runTimer] = useState(0);
  const [data, setData] = useState({ 'img': {}, 'glb': {} });

  // const account = metaState.account[0];
  const [timeLeft, { start, reset }] = useCountDown(initialTime, interval);

  useEffect(() => {
    getDownloadLinks();
  }, []);

  const getLeadingZero = value => {
    let safeValue = value;
    if (+safeValue < 10) safeValue = `0${safeValue}`;
    return safeValue;
  };

  const getDownloadLinks = async () => {
    const message = `Align the Many Moons, take shelter from the Starfall showers, prepare the NRG downloader for Voxo #${padLeadingZeros(
      selectedVoxo,
      4,
    )} on timestamp: ${Date.now()}`;

    runTimer(0);
    try {
      const signature = await library.send('personal_sign', [message, account.toLowerCase()]);

      const data = { signature, message, account };
      // const { data : response } = await axios.post(`http://localhost:8000/api/collections/voxodeus/${selectedVoxo}/unlock`, data);
      const { data : response } = await axios.post(`https://api.cypherverse.io/api/collections/voxodeus/${selectedVoxo}/unlock`, data);
      const { validity_in_seconds } = response;

      if (validity_in_seconds) {
        setData(response);
        // runTimer(10000);
        runTimer(validity_in_seconds * 1000);
        toggleAllDataLoaded(true);
      }
    } catch {
      setSelectedVoxo('');
    }
  };
  const minutes = useMemo(() => Math.floor(((timeLeft / 1000) % 3600) / 60), [timeLeft]);
  const seconds = useMemo(() => getLeadingZero(Math.floor(((timeLeft / 1000) % 3600) % 60)), [timeLeft]);

  useEffect(() => {
    if (timer) {
      start(timer);
    } else {
      reset();
    }
  }, [timer]);

  const inTime = useMemo(() => !!timeLeft, [timeLeft]);
  const handleDownloadClick = async (url, fileName, type) => {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = `Voxo${padLeadingZeros(selectedVoxo, 4)}_${fileName.replaceAll(' ', '_')}${
      type === 'image' ? '' : '.glb'
    }`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Overlay>
      <CloseModalButton onClick={() => setSelectedVoxo('')}>
        <CLOSE_ICON_ICON />×
      </CloseModalButton>
      <Modal>
        <ModalHeader>
          <div>downloadS</div>
          <VoxoId>voxo #{selectedVoxo}</VoxoId>
        </ModalHeader>
        <ModalBody>
          {allDataLoaded ? (
            <>
              <TextBlock>
                {inTime ? (
                  <>
                    Download links valid for: <Timer>{`${minutes} minutes ${seconds} seconds`}</Timer>
                  </>
                ) : (
                  <>
                    Download links expired.
                    <ClickToRefresh onClick={getDownloadLinks}>Click here to refresh</ClickToRefresh>.
                  </>
                )}
              </TextBlock>
              <Files>
                <FilesList>
                  <FilesListHeader>2D images (PNG)</FilesListHeader>
                  <div>
                    {Object.keys(data['img']).map(file => (
                      <File
                        inTime={inTime}
                        onClick={() => handleDownloadClick(data['img'][file], file, 'image')}
                        key={file}
                      >
                        <DownloadIcon />
                        {file}
                      </File>
                    ))}
                  </div>
                </FilesList>
                <FilesList>
                  <FilesListHeader>3D models (GLB)</FilesListHeader>
                  <div>
                    {Object.keys(data['glb']).map(file => (
                      <File
                        inTime={inTime}
                        onClick={() => handleDownloadClick(data['glb'][file], file, 'glb')}
                        key={file}
                      >
                        <DownloadIcon />
                        {file}
                      </File>
                    ))}
                  </div>
                </FilesList>
              </Files>
            </>
          ) : (
            <SignMessage>Please sign on MetaMask to proceed.</SignMessage>
          )}
          <ButtonsContainer>
            <ContinueButton onClick={() => setSelectedVoxo('')}>Done</ContinueButton>
          </ButtonsContainer>
        </ModalBody>
      </Modal>
    </Overlay>
  );
};

export default DownloadModal;
