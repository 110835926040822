import React, {useContext, useMemo} from 'react';
import { CollectionPageStyle } from './rewardsPage.styled';
import Footer from 'components/footer';
import DialoguesContext from './Dialogues/DialoguesContext';
import MyRewards from './MyRewards';
import { useWeb3React } from '@web3-react/core';
import {AppContext} from "../../context/AppContext";
import MobilePage from "./MobilePage";

const RewardsPage = () => {
  const { active, chainId } = useWeb3React();
  const showPage = useMemo(() => active && (chainId === 1 || chainId === 4), [active, chainId]);
  const { isMobile } = useContext(AppContext);

  return (
    <CollectionPageStyle>
      {showPage ? <MyRewards /> : isMobile ? <MobilePage /> : <DialoguesContext isConnected={active} />}
      {showPage ? <Footer /> : null }
    </CollectionPageStyle>
  );
};

export default RewardsPage;
