import React, { useState, useEffect, useMemo } from 'react';
import useDebounce from 'hooks/useDebounce';
import axios from 'axios';
import SearchIcon from 'assets/icons/search_icon.svg';
import CancelIcon from 'assets/icons/cancel_icon.svg';
import {
  FiltersQnt,
  GalleryContainer,
  GalleryData,
  MobileFilterButton,
  PageTitle,
  ScrollButtonContainer,
  ScrollTopButton,
  VoxoIdSearch,
  VoxoIdSearchContainer,
  VoxoIdSearchControls,
  VoxoIdWrapper,
  VoxoSearchHash,
  VoxosLength,
} from './gallery.styled';
import VoxoGallery from './VoxoGallery';
import Filters from './Filters';
import { LoadingGalleryState } from './VoxoGallery/LoadingState';
import { useLocation } from 'react-router';

const LIMIT = 48;
const GalleryPage = () => {
  const [pages, setPages] = useState({ page: 0, totalVoxos: 0 });
  const [voxosArr, setVoxosArr] = useState([]);
  const [filters, setFilters] = useState();
  const [isLoading, toggleIsLoading] = useState(false);
  const { search: urlParams } = useLocation();

  const debouncedSearchTerm = useDebounce(filters, 1800);
  useEffect(() => {
    setVoxosArr([]);
    toggleIsLoading(true);
    if (filters) {
      fetchAssets(0).then(response => {
        if (response) {
          setVoxosArr(response);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          toggleIsLoading(false);
        }
      });
    }
  }, [debouncedSearchTerm]);

  const getStringSearchParams = (species, rarity, factions, archetypes) => {
    let stringParams = '';
    if (species) {
      stringParams = stringParams ? `${stringParams},${species}` : `${species}`;
    }
    if (rarity) {
      stringParams = stringParams ? `${stringParams},${rarity}` : `${rarity}`;
    }
    if (factions) {
      stringParams = stringParams ? `${stringParams},${factions}` : `${factions}`;
    }
    if (archetypes) {
      stringParams = stringParams ? `${stringParams},${archetypes}` : `${archetypes}`;
    }
    return encodeURIComponent(`{${stringParams}}`);
  };

  const fetchAssets = async page => {
    let species =
      filters && filters.species
        ? Object.keys(filters.species)
            .reduce((out, specy) => {
              if (specy !== 'all' && !!filters.species[specy]) {
                out.push(`"${specy}"`);
              }
              return out;
            }, [])
            .join(',')
        : '';
    species = species ? `"#1 Species":[${species}]` : '';
    let rarity =
      filters && filters.rarity
        ? Object.keys(filters.rarity)
            .reduce((out, specy) => {
              if (specy !== 'all' && !!filters.rarity[specy]) {
                out.push(`"${specy}"`);
              }
              return out;
            }, [])
            .join(',')
        : '';
    rarity = rarity ? `"#2 Rarity":[${rarity}]` : '';
    let factions =
      filters && filters.factions
        ? Object.keys(filters.factions)
            .reduce((out, specy) => {
              if (specy !== 'all' && !!filters.factions[specy]) {
                out.push(`"${specy}"`);
              }
              return out;
            }, [])
            .join(',')
        : '';
    factions = factions ? `"#3 Faction":[${factions}]` : '';
    let archetypes =
      filters && filters.archetypes
        ? Object.keys(filters.archetypes)
            .reduce((out, specy) => {
              if (specy !== 'all' && !!filters.archetypes[specy]) {
                out.push(`"${specy}"`);
              }
              return out;
            }, [])
            .join(',')
        : '';
    archetypes = archetypes ? `"#4 Archetype":[${archetypes}]` : '';

    const order = filters && (filters.sortBy === 'Descending' || filters.sortBy === 'RarityDesc') ? 'desc' : 'asc';
    const sort = filters && (filters.sortBy === 'RarityDesc' || filters.sortBy === 'RarityAsc') ? 'rarity' : 'token_id';
    const voxoId =
      filters && filters.voxoId && parseInt(filters.voxoId) ? `{"token_id" : [${parseInt(filters.voxoId) || ''}]}` : '';
    const searchFilters =
      filters && filters.voxoId && parseInt(filters.voxoId)
        ? voxoId
        : getStringSearchParams(species, rarity, factions, archetypes);
    const response = await axios.get(
      `https://api.cypherverse.io/api/collections/voxodeus/search?summary=true&incl_unminted=false&q=${searchFilters}&offset=${page}&limit=${LIMIT}&sort=${sort}&direction=${order}`,
    );
    setPages({
      page,
      totalVoxos: +response.data.count,
    });
    return response.data.result;
  };

  const showMoreData = () => {
    if (pages.totalVoxos && voxosArr.length < pages.totalVoxos) {
      fetchAssets(pages.page + LIMIT).then(response => {
        if (response) {
          setVoxosArr([...voxosArr, ...response]);
        }
      });
    }
  };
  const [showFilters, toggleShowFilters] = useState(false);

  const appliedFilters = useMemo(() => {
    if (!filters) return 0;
    return Object.keys(filters).reduce((out, filter) => {
      if (typeof filters[filter] !== 'string') {
        const groomedFilters = [...Object.values(filters[filter])];
        groomedFilters.splice(-1);
        Object.values(filters[filter]).forEach(item => (out += +item));
      }
      return out;
    }, 0);
  }, [filters]);
  const [inputIsFocused, toggleInputIsFocused] = useState(false);
  return (
    <GalleryContainer showFilters={showFilters}>
      <PageTitle>Gallery</PageTitle>
      <VoxoIdSearchContainer>
        <VoxoIdWrapper>
          {!filters || (filters && !filters.voxoId && !inputIsFocused) ? <span>Search by Voxo #</span> : null}
          {((filters && filters.voxoId) || inputIsFocused) && <VoxoSearchHash>#</VoxoSearchHash>}
          <VoxoIdSearch
            value={filters ? filters.voxoId || '' : ''}
            onFocus={() => toggleInputIsFocused(true)}
            onBlur={() => toggleInputIsFocused(false)}
            onChange={({ target: { value } }) => setFilters({ ...filters, voxoId: value })}
          />
          <VoxoIdSearchControls
            onClick={() => {
              if (filters && filters.voxoId) {
                setFilters({ ...filters, voxoId: '' });
              }
            }}
            isEmpty={!filters || (filters && !filters.voxoId)}
            src={!filters || (filters && !filters.voxoId) ? SearchIcon : CancelIcon}
            alt=""
          />
        </VoxoIdWrapper>
        {pages.totalVoxos ? (
          <VoxosLength>
            Showing <b>{pages.totalVoxos}</b> voxos
          </VoxosLength>
        ) : null}
      </VoxoIdSearchContainer>
      <GalleryData>
        <Filters
          filters={filters}
          setFilters={setFilters}
          toggleShowFilters={toggleShowFilters}
          showFilters={showFilters}
          appliedFilters={appliedFilters}
          urlParams={urlParams}
        />
        {isLoading && <LoadingGalleryState />}
        {voxosArr.length ? (
          <VoxoGallery
            showFilters={showFilters}
            voxos={voxosArr}
            showMoreData={showMoreData}
            hasMore={pages.totalVoxos && voxosArr.length < pages.totalVoxos}
            dataLength={pages.totalVoxos}
          />
        ) : null}
        <ScrollButtonContainer showFilters={showFilters}>
          <ScrollTopButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </ScrollButtonContainer>
        <MobileFilterButton onClick={() => toggleShowFilters(true)}>
          Filter {!!appliedFilters && <FiltersQnt>{appliedFilters}</FiltersQnt>}
        </MobileFilterButton>
      </GalleryData>
    </GalleryContainer>
  );
};
export default GalleryPage;
