import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Overlay,
  ButtonsContainer,
  CloseModalButton,
  TextBlockMedium,
  TextBlockSmall,
  TextBlockLarger,
  TextareaBlock,
  ClaimButton,
  ClaimButtonInactive,
  NpsButtonBlock,
  NpsContainerBlock,
  UnlikelyExtremelyLikelyBlock, TextBlockThankYou
} from './claim.styled';
import {ReactComponent as CLOSE_ICON_ICON} from 'assets/icons/close-button-icon.svg';
import { EXTERNAL_URLS } from '../../../../../utils/constants';

const ClaimModal = ({closeModal, claimInner, modalCountdownStart}) => {
  const [data, setData] = useState({initial_appeal: '', suggestion: '', nps: '', created_at: ''});

  const [countdownValue, setCountdownValue] = useState(0);

  const disableClick = () => {
    return !data.initial_appeal || !data.suggestion || !data.nps;
  };

  const handleSubmit = () => {
    const message = {
      ...data,
      created_at: (new Date()).toISOString()
    }
    claimInner(message);
  };

  const npsValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const npsButton = (value, i) => <NpsButtonBlock key={i} style={{
    color: data.nps === value ? '#fff' : '#000000',
    background: data.nps === value ? '#000000' : '#fff'
  }}
                                                  onClick={() => setData({
                                                    ...data,
                                                    nps: value
                                                  })}>{value}</NpsButtonBlock>;

  const startThankYouCountdown = () => {
    setCountdownValue(5);
  };

  React.useEffect(() => {
    countdownValue > 0 && setTimeout(() => setCountdownValue(countdownValue - 1), 1000);
  }, [countdownValue]);

  useEffect(() => {
    if (modalCountdownStart) {
      startThankYouCountdown();
    }
  }, [
    modalCountdownStart
  ]);

  return (
      <Overlay>
        <CloseModalButton onClick={() => closeModal()}>
          <CLOSE_ICON_ICON/>×
        </CloseModalButton>
        <Modal>
          <ModalHeader>
            <div>claim process</div>
          </ModalHeader>
          <ModalBody>
            {modalCountdownStart ? (
                <>
                  <TextBlockThankYou style={{marginTop: 261}}>
                    Thank you for your submission!
                  </TextBlockThankYou>
                  <TextBlockThankYou style={{marginTop: 25}}>
                    Taking you to your Rewards activity in <span>{countdownValue}</span>...
                  </TextBlockThankYou>
                </>
            ) : (
                <>
                  <TextBlockMedium>
                    Before completing your claim, we just need you to answer a few questions.
                  </TextBlockMedium>
                  <TextBlockLarger>
                    1. What drew you to VoxoDeus?
                  </TextBlockLarger>
                  <TextareaBlock id="initial_appeal" name="initial_appeal"
                                 value={data.initial_appeal}
                                 onChange={(event) => setData({...data, initial_appeal: event.target.value})}
                                 style={{borderColor: data.initial_appeal ? '#B3C3E2' : '#FF6A84'}}/>
                  <TextBlockLarger>
                    2. What do you think could be improved?
                  </TextBlockLarger>
                  <TextareaBlock id="suggestion" name="suggestion" value={data.suggestion}
                                 onChange={(event) => setData({...data, suggestion: event.target.value})}
                                 style={{borderColor: data.suggestion ? '#B3C3E2' : '#FF6A84'}}/>
                  <TextBlockLarger>
                    3. How likely are you to recommend Voxos to your NFT peers?
                  </TextBlockLarger>
                  <NpsContainerBlock>
                    {npsValues.map((x, i) => npsButton(x, i))}
                  </NpsContainerBlock>
                  <UnlikelyExtremelyLikelyBlock><span>Unlikely</span><span>Extremely likely</span></UnlikelyExtremelyLikelyBlock>
                  <ButtonsContainer>
                    {disableClick() ? <ClaimButtonInactive>Submit & sign message</ClaimButtonInactive> :
                        <ClaimButton onClick={() => handleSubmit()}><span>Submit & sign message</span></ClaimButton>}
                  </ButtonsContainer>
                  <TextBlockSmall>
                    <ul>
                      <li>Your answers will be kept private, confidential and accessible by VoxoDeus only.</li>
                    </ul>
                  </TextBlockSmall>
                  <TextBlockSmall>
                    <ul>
                      <li>Please do not provide any Personal Data, such as any information that could identify you or
                        anyone else, or any other sensitive information (e.g. passwords).
                      </li>
                    </ul>
                  </TextBlockSmall>
                  <TextBlockSmall>
                    <ul>
                      <li>Your answers would only be used to improve our services, such as by analyses of aggregated
                        statistical findings and insights.
                      </li>
                    </ul>
                  </TextBlockSmall>
                  <TextBlockSmall>
                    <ul>
                      <li>Please refer to our <a href={EXTERNAL_URLS.TCV_PRIVACY}
                          target="_blank">Privacy Policy</a> for further information, or contact us if you have any
                        questions.
                      </li>
                    </ul>
                  </TextBlockSmall>
                </>
            )}
          </ModalBody>
        </Modal>
      </Overlay>
  );
};

export default ClaimModal;
