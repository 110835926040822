import React, { useState } from 'react';
import { GalleryContainer, GalleryData, ScrollButtonContainer, ScrollTopButton } from './gallery.styled';
import VoxoGallery from './VoxoGallery';

const PAGE_SIZE = 100;
const GalleryPage = ({ voxosArr, fetchNextBatch, totalVoxos }) => {
  const [renderedVoxosArr, setRenderedVoxosArr] = useState(voxosArr.slice(0, PAGE_SIZE));
  const [hasMore, toggleHasMore] = useState(voxosArr.length < totalVoxos);

  const showMoreData = () => {
    if (voxosArr.length >= totalVoxos) {
      toggleHasMore(false);
      return;
    } else {
      fetchNextBatch();
    }
    setRenderedVoxosArr(voxosArr.slice(0, renderedVoxosArr.length + PAGE_SIZE));
  };
  return (
    <GalleryContainer>
      <GalleryData>
        {voxosArr.length ? (
          <VoxoGallery
            showFilters={false}
            voxos={voxosArr}
            showMoreData={showMoreData}
            hasMore={hasMore}
            dataLength={voxosArr.length}
          />
        ) : null}
        <ScrollButtonContainer>
          <ScrollTopButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </ScrollButtonContainer>
      </GalleryData>
    </GalleryContainer>
  );
};
export default GalleryPage;
