import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  CheckboxContainer,
  CheckboxImage,
  CheckboxInput,
} from "./checkbox.styled";

const Checkbox = ({ label, value = false, onChange }) => {
  return (
    <CheckboxContainer onClick={() => onChange(!value)}>
      <CheckboxImage isChecked={value} />
      <div>{label}</div>
    </CheckboxContainer>
  );
};
export default Checkbox;
