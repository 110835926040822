import styled from 'styled-components';
import ChevronDown from 'assets/icons/chevron_down.svg';
import * as size from 'assets/CSSPalette/sizes';
import * as color from 'assets/CSSPalette/colors';
import media from 'themes/media';

export const RewardsWrapper = styled.main`
  position: relative;
  top: 20px;
  color: #fff;
  font-size: 25px;
  margin: 0 auto;
  max-width: 1300px;
`;

export const RewardsInnerWrapper = styled.main`
  width: 100%;
  display: flex;
`;

export const ActivityGrid = styled.main`
  position: relative;
  width: 70%;
  border: 1px solid ${color.TRON_2};
  border-radius: 10px;
  background-color: ${color.TRON_1};;
  padding-bottom: 38px;
  margin-right: 10px;
  max-width: 818px;
`;

export const BalanceGrid = styled.main`
  position: relative;
  width: 33%;
  border: 1px solid ${color.TRON_2};
  border-radius: 10px;
  background-color: ${color.TRON_1};;
  padding-bottom: 38px;
  margin-left: 10px;
  max-height: 438px;
`;

export const ActivityClaimData = styled.div`
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  margin-top: 70px;
  padding-bottom: ${size.FOOTER_HEIGHT};
  display: flex;
  justify-content: space-between;
  ${media.mobile`
  margin-top: 30px;
 `}
`;

export const ActivityHeader = styled.div`
  margin-top: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  &::before {
    content: '';
    position: absolute;
    width: 89px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    left: 21%;
    top: 60px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 89px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    right: 21%;
    top: 60px;
  }
`;

export const BalanceHeader = styled.div`
  margin-top: 50px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  &::before {
    content: '';
    position: absolute;
    width: 45px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    left: 15%;
    top: 60px;
  }
  &::after {
    content: '';
    position: absolute;
    width: 45px;
    height: 0;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    right: 15%;
    top: 60px;
  }
`;

export const AvailableBalance = styled.div`
  width: 320px;
  height: 125px;
  margin: 44px 40px 15px 40px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${color.TRON_7};
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  border: 1px solid #2A4060;
  box-sizing: border-box;
  border-radius: 5px;
  > p {
  padding: 0;
  margin: 0;
  > span {
  color: white;
  font-weight: 700;
  }
  }
`;

export const WithdrawAllButton = styled.div`
  margin: 0 auto;
  border-radius: 10px 2px 10px 2px;
  width: 320px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 0 0 2px #8c86ff;
    background: linear-gradient(266.38deg, #cc3767 0%, #ab5fb4 49.18%, #8c86ff 100%);
    > span {
      transition: transform 0.2s ease-out, filter 0.2s ease-out;
      transform: scale(1.05);
    }
  }
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
  }
`;

export const WithdrawAllButtonInactive = styled.div`
  margin: 0 auto;
  border-radius: 10px 2px 10px 2px;
  width: 320px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A4060;
  transition: all 0.1s ease-out;
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0.3;
  }
`;

export const WithdrawAllText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${color.TRON_5};
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${color.TRON_8};
  }
`;

export const WhyWaitText = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-decoration-line: underline;
  color: #8C86FF;
  padding-top: 14px;
`;

export const AvailableBalanceText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const AvailableBalanceValueText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  text-transform: uppercase;
`;

export const AvailableBalanceExpiresText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: ${color.TRON_5};
  letter-spacing: 0.01em;
  > span {
  font-weight: 600;
  letter-spacing: 0.01em;
  }
`;

export const ActivitySubHeader = styled.div`
  margin: 40px 0;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${color.TRON_7};
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  > p {
  padding: 0;
  margin: 0;
  letter-spacing: 0.01em;
  > span {
  color: white;
  font-weight: 700;
  letter-spacing: 0.01em;
  }
  }
`;

export const ActivityGridItem = styled.div`
  margin: 0;
  width: 100%;
  padding: 0 40px;
  display: flex;
  transition: all 0.1s ease-out;
`;

export const ActivityGridItemBg = styled.div`
  margin: 0;
  width: 100%;
  padding: 38px 40px;
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const ActivityGridItemSeparator = styled.div`
  margin: auto;
  width: 90%;
  padding: 0 80px;
  border-bottom: 1px solid #2A4060;
  display: flex;
`;

export const ActivityGridItemHeader = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${color.VOID_7};
  opacity: 0.5;
  margin-bottom: 9px;
`;

export const ActivityGridItemStatus = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  align-items: start;
  > li {
       font-size: 13px;
       display:inline-block;
       padding:0;
      }
`;

export const ActivityGridItemMintedHeld = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  align-items: start;
`;

export const ActivityGridItemIcon = styled.div`
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding-top: 20px;
`;

export const ClaimButton = styled.div`
  position: absolute;
  bottom: -6px;
  right: -50px;
  border-radius: 10px 2px 10px 2px;
  width: 120px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    background: linear-gradient(266.38deg, #cc3767 0%, #ab5fb4 49.18%, #8c86ff 100%);
    box-shadow: inset 0 0 0 2px #8c86ff;
    > span {
      transition: transform 0.2s ease-out, filter 0.2s ease-out;
      transform: scale(1.05);
    }
  }
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
  }
`;

export const ClaimButtonInactive = styled.div`
  position: absolute;
  bottom: -6px;
  right: -50px;
  border-radius: 10px 2px 10px 2px;
  width: 120px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A4060;
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0.3;
  }
`;

export const GalleryControls = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease-in;
  position: relative;
  ${media.mobile`
    position:fixed;
    width:calc(100% - 50px);
    background:#0B1528;
    padding: 38px;
    z-index: 1000;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    left:${({ showFilters }) => (showFilters ? 0 : `calc(-100% + 50px)`)};
    padding-bottom: 130px;
 `}
`;
export const CheckboxGroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin: 40px 0 20px 0;
`;
export const CheckboxContainer = styled.div`
  margin-bottom: 16px;
`;
export const CheckboxGroupLine = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
  margin-top: 24px;
`;
export const VoxosGridBottomLine = styled(CheckboxGroupLine)`
  position: absolute;
  bottom: 0;
`;
export const VoxosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
  position: relative;
  ${media.mobile`
  padding:16px;
 `}
`;
export const VoxoGridItemImageContainer = styled.div`
  background-color: #1b1437;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
`;
export const VoxoGridItemImage = styled.img`
  width: 100%;
`;
export const MoreFiltersButton = styled.div`
  color: ${color.VOID_8};
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    color: ${color.VOID_6};
  }
`;
export const ResetFiltersButton = styled(MoreFiltersButton)`
  margin-top: 40px;
  display: flex;
  align-items: center;
  img {
    margin-right: 15px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgb(255 255 255 / 20%);
  background: none;
  font-size: 14px;
  color: #fff;
  margin-bottom: 16px;
  &::placeholder {
    color: ${color.TRON_4};
    opacity: 1;
  }
`;
export const LoadingText = styled.div`
  padding: 32px;
  font-size: 16px;
  font-weight: 500;
  font-family: IBM Plex Sans;
`;
export const ScrollButtonContainer = styled.div`
  position: fixed;
  bottom: 40px;
  max-width: 1300px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  pointer-events: ${({ showFilters }) => (showFilters ? 'none' : 'unset')};
  @media screen and (max-width: 1300px) {
    right: 20px;
    width: calc(100% - 24px);
  }
`;
export const ScrollTopButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(83.8deg, #8c86ff 0.98%, #ab5fb4 50.73%, #cc3767 102.13%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1300px) {
    right: 20px;
  }
  &:after {
    content: url(${ChevronDown});
    padding-top: 10px;
    transform: rotate(180deg);
  }
  &:hover {
    background: linear-gradient(160.8deg, #8c86ff 0.98%, #ab5fb4 50.73%, #cc3767 102.13%);
  }
`;
export const MobileResetButton = styled.div`
  display: none;
  ${ResetFiltersButton} {
    margin-top: 16px;
  }
  ${media.mobile`
    display: flex;
    flex-direction:column;
 `}
`;
export const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Outage';
  font-size: 38px;
  font-weight: bold;
  margin-top: 60px;
`;
export const VoxoIdSearchContainer = styled.div`
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.mobile`
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-end;
    padding:24px 24px 24px 64px;
 `}
`;
export const VoxoIdWrapper = styled.div`
  width: 465px;
  height: 27px;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
  margin-left: 33px;
  position: relative;
  ${media.mobile`
    width: 100%;
    margin-bottom:16px;
 `}
  > span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;
    position: absolute;
    top: 0px;
    top: -9px;
    background: linear-gradient(269deg, #f1aebb 1.22%, #8c86ff 98.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &::before {
    content: '';
    width: 39px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    transform: rotate(45deg);
    position: absolute;
    left: -33px;
    top: 12px;
  }
`;
export const VoxosLength = styled.div`
  font-size: 15px;
`;
export const LoadingWrapper = styled.div`
  width: calc(100% - 75px);
  height: 80px;
  border-radius: 10px;
  background: #0e1c34;
  display: flex;
  align-items: center;
  padding-left: 42px;
  text-shadow: 1px 2px 10px #d7597b;
  margin: 75px 0 0 32px;
  font-size: 16px;
  ${media.mobile`
    width: calc(100% - 56px);
 `}
  > img {
    width: 20px;
    margin-right: 16px;
  }
`;
export const VoxoImageControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  align-items: flex-start;
`;

export const VoxoData = styled.div`
  display: flex;
  flex-direction: column;
  a {
    border-radius: 5px;
    padding: 4px 6px;
    position: relative;
    left: -6px;
    &:hover {
      background: #172a49;
    }
  }
  > span {
    color: #56f6ce;
    font-weight: 300;
    font-size: 12px;
    > span {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;
export const FAQBox = styled.div`
  width: 400px;
  height: 100px;
  margin-top: 50px;
  background-color: #FFFFFF;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${color.TRON_5};
  border: 1px solid #2A4060;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 40px 0 33px;
  transition: all 0.6s ease;
  &:hover {
      background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
      > div {
        > div {
          > p {
            color: #B3C3E2;
            > span {
              color: white;
            }
          }
        }
      }
    }
  > div {
  display: flex;
    > div {
  margin-left: 17px;
  justify-content: left;
  text-align: left;
  > p {
  color: #5D7195;
  padding: 0 0 8px 0;
  margin: 0;
  > span {
  color: #0E1C34;
  text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  }
  }
  }
  }
`;

export const CancelButton = styled.div`
  position: absolute;
  bottom: -6px;
  right: -50px;
  border-radius: 10px 2px 10px 2px;
  width: 120px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5793FF;
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    box-shadow: inset 0 0 0 2px #8c86ff;
    > span {
      transition: transform 0.2s ease-out, filter 0.2s ease-out;
      transform: scale(1.05);
    }
  }
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
  }
`;

export const CancelButtonInactive = styled.div`
  position: absolute;
  bottom: -6px;
  right: -50px;
  border-radius: 10px 2px 10px 2px;
  width: 120px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A4060;
  > span {
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0.3;
  }
`;
