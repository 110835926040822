import React, { useEffect, useState } from 'react';
import { useMetamask } from 'use-metamask';
import MyRewardsHeader from './MyRewardsHeader';
import ActivityClaimPage from './ActivityClaim';
import { LoadingGalleryState } from './ActivityClaim/ActivityClaim/LoadingState';
import { getUserName } from 'services/openSeaService';
import {getRewardsForAddress, postClaim, postWithdrawAll, deleteWithdrawAll} from 'services/rewardsService';
import { useWeb3React } from '@web3-react/core';
import {useLocation} from "react-router-dom";

const MyRewards = (props) => {
  const { active, chainId, account } = useWeb3React();
  const { metaState } = useMetamask();
  const [rewardsData, setRewardsData] = useState({});
  const [loading, toggleLoading] = useState(true);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('testaddress');

  useEffect(() => {
    if (active && chainId === 1) {
      toggleLoading(true);
      setRewardsData({});
      const userId = account;
      // const userId = metaState.account[0];
      // const userId = '0x9be96a6e861d2e5aff1fe0738bed664b6f0b543e';

      // fetch user name from OpenSea
      getUserName(userId)
        .then(data => {
          const {
            data: {
              data: { user },
            },
          } = data;
        })
        .catch(err => console.log(err));

      // fetch user's rewards
      getRewardsForAddress(userId, name || null)
        .then(data => {
          setRewardsData(data.data);
          toggleLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, [metaState.account]);

  const updateRewardsData = (data) => {
    setRewardsData(data);
  }

  const updateRewardsSingleActivityData = (newRowData, i) => {
    const newRewards = rewardsData;
    newRewards.activity[i] = newRowData;

    setRewardsData(newRewards);
  }

  return (
    <div style={{ minHeight: `calc(100vh - 298px)` }}>
      <MyRewardsHeader rewardsData={rewardsData} account={account} />
      {loading ? (
        <LoadingGalleryState />
      ) : (
        <>
          {rewardsData ? (
            <ActivityClaimPage
              rewardsData={rewardsData}
              postWithdrawAll={postWithdrawAll}
              postClaim={postClaim}
              deleteWithdrawAll={deleteWithdrawAll}
              updateRewardsData={updateRewardsData}
              updateRewardsSingleActivityData={updateRewardsSingleActivityData}
            />
          ) : (
              <LoadingGalleryState />
          )}
        </>
      )}
    </div>
  );
};

export default MyRewards;
