import React, { useEffect, useState } from 'react';
import {
  LoadingText,
  VoxoGalleryWrapper,
  VoxoGridItemImage,
  VoxoGridItemImageContainer,
  VoxosGrid,
  VoxosGridBottomLine,
} from 'containers/GalleryPage/gallery.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { withThousandsSeparator } from 'utils/numberFormat';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon.svg';
import { VoxoGridItem, VoxoData, VoxoImageControls, VoxoGridItemArchetype } from '../gallery.styled';
import DownloadModal from './DownloadModal';

const VoxoGallery = ({ voxos, showMoreData, hasMore, showFilters }) => {
  const [showDownloadModal, toggleShowDownloadModal] = useState(false);
  const [selectedVoxo, setSelectedVoxo] = useState('');

  const handleDownloadButtonClick = id => {
    setSelectedVoxo(id);
    // toggleShowDownloadModal(true);
  };
  useEffect(() => {
    if (selectedVoxo) {
      toggleShowDownloadModal(true);
    } else {
      toggleShowDownloadModal(false);
    }
  }, [selectedVoxo]);
  const [linkMouseOver, setLinkMouseOver] = useState(false);
  return (
    <VoxoGalleryWrapper showFilters={showFilters}>
      <InfiniteScroll
        dataLength={voxos.length}
        next={showMoreData}
        hasMore={hasMore}
        loader={hasMore ? <LoadingText>Loading...</LoadingText> : null}
      >
        <VoxosGrid>
          {voxos.map((item, i) => (
            <VoxoGridItem
              key={i}
              onClick={() => handleDownloadButtonClick(item.token_id)}
              linkMouseOver={linkMouseOver}
            >
              <VoxoGridItemImageContainer>
                <VoxoGridItemImage src={item.image} />
              </VoxoGridItemImageContainer>
              <VoxoImageControls>
                <VoxoData>
                  <NavLink
                    to={`/voxo/${item.token_id}`}
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                    onMouseEnter={() => setLinkMouseOver(true)}
                    onMouseLeave={() => setLinkMouseOver(false)}
                  >
                    {item.name}
                  </NavLink>
                  <VoxoGridItemArchetype>{item.traits['#4 Archetype']}</VoxoGridItemArchetype>
                  <span>
                    Rank <span>{item.rarity.rank}</span>
                  </span>
                  <span>
                    Score <span>{withThousandsSeparator(Math.round(item.rarity.score))}</span>
                  </span>
                </VoxoData>
                <DownloadIcon />
              </VoxoImageControls>
            </VoxoGridItem>
          ))}
          {hasMore && <VoxosGridBottomLine />}
        </VoxosGrid>
      </InfiniteScroll>
      {showDownloadModal && <DownloadModal selectedVoxo={selectedVoxo} setSelectedVoxo={setSelectedVoxo} />}
    </VoxoGalleryWrapper>
  );
};
export default VoxoGallery;
