export const toDateString = (time, format = 'short', tz=undefined) => {
  const date = new Date(time);
  if (format === 'short') {
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: tz
    });
  }
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: tz ? tz : 'utc',
    timeZoneName: 'short',
  });
};

export const formatAMPM = (hours, minutes = undefined) => {
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strTime = minutes === undefined ? hours + ampm : `${hours}:${minutes}${ampm}`;
  return strTime;
};
