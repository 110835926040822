import styled from 'styled-components';
import ChevronDown from 'assets/icons/chevron_down.svg';
import * as size from 'assets/CSSPalette/sizes';
import * as color from 'assets/CSSPalette/colors';
import media from 'themes/media';

export const GalleryContainer = styled.main`
  position: relative;
  top: 80px;
  color: #fff;
  font-size: 25px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    padding: 0 24px;
  }
  ${media.mobile`
    padding:0;
    height: ${({ showFilters }) => (showFilters ? '100vh' : 'unset')};
    overflow: ${({ showFilters }) => (showFilters ? 'hidden' : 'unset')};
 `}
`;

export const GalleryData = styled.div`
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  margin-top: 70px;
  padding-bottom: ${size.FOOTER_HEIGHT};
  display: flex;
  justify-content: space-between;
  ${media.mobile`
  margin-top: 30px;
 `}
`;
export const GalleryControls = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease-in;
  position: relative;
  ${media.mobile`
    position:fixed;
    width:calc(100% - 50px);
    background:#0B1528;
    padding: 38px;
    z-index: 1000;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    left:${({ showFilters }) => (showFilters ? 0 : `calc(-100% + 50px)`)};
    padding-bottom: 130px;
 `}
`;
export const CheckboxGroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin: 40px 0 20px 0;
`;
export const CheckboxContainer = styled.div`
  margin-bottom: 16px;
`;
export const CheckboxGroupLine = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
  margin-top: 24px;
`;
export const VoxosGridBottomLine = styled(CheckboxGroupLine)`
  position: absolute;
  bottom: 0;
`;
export const VoxosGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
  position: relative;
  ${media.mobile`
  padding:16px;
 `}
`;
export const VoxoGridItemImageContainer = styled.div`
  background-color: #1b1437;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
`;
export const VoxoGridItemImage = styled.img`
  width: 100%;
`;
export const VoxoGridItem = styled.div`
  cursor: pointer;
  margin: 0 7px;
  width: calc(25% - 16px);
  padding: 10px 10px 18px 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: IBM Plex Sans;
  margin-bottom: 42px;
  background: #0e1c34;
  border-radius: 15px;
  &:hover {
    background: ${({ linkMouseOver }) => (linkMouseOver ? '#0e1c34' : '#172a49')};
  }
  ${media.mobile`
    width: calc(50% - 16px);
    padding-left: 10px;
    padding-right: 10px;
 `}
`;
export const MoreFiltersButton = styled.div`
  color: ${color.VOID_8};
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    color: ${color.VOID_6};
  }
`;
export const ResetFiltersButton = styled(MoreFiltersButton)`
  margin-top: 40px;
  display: flex;
  align-items: center;
  img {
    margin-right: 15px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgb(255 255 255 / 20%);
  background: none;
  font-size: 14px;
  color: #fff;
  margin-bottom: 16px;
  &::placeholder {
    color: ${color.TRON_4};
    opacity: 1;
  }
`;
export const LoadingText = styled.div`
  padding: 32px;
  font-size: 16px;
  font-weight: 500;
  font-family: IBM Plex Sans;
`;
export const ScrollButtonContainer = styled.div`
  position: fixed;
  bottom: 40px;
  max-width: 1240px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  pointer-events: ${({ showFilters }) => (showFilters ? 'none' : 'unset')};
  @media screen and (max-width: 1300px) {
    right: 20px;
    width: calc(100% - 24px);
  }
`;
export const ScrollTopButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(83.8deg, #8c86ff 0.98%, #ab5fb4 50.73%, #cc3767 102.13%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1300px) {
    right: 20px;
  }
  &:after {
    content: url(${ChevronDown});
    padding-top: 10px;
    transform: rotate(180deg);
  }
  &:hover {
    background: linear-gradient(160.8deg, #8c86ff 0.98%, #ab5fb4 50.73%, #cc3767 102.13%);
  }
`;
export const FiltersQnt = styled.div`
  width: 25px;
  height: 25px;
  background: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 8px;
`;
export const MobileFilterButton = styled.div`
  position: fixed;
  bottom: 40px;
  width: calc(100% - 110px);
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  box-shadow: 1px 2px 80px rgba(11, 21, 40, 0.5);
  border-radius: 10px 2px 2px 2px;
  display: none;
  justify-content: center;
  color: #ffffff;
  text-shadow: 0px 0.698917px 43.6823px #ab9be9;
  font-size: 15px;
  margin: 0 30px;
  height: 45px;
  align-items: center;
  ${media.mobile`
    display: flex;
 `}
`;
export const MobileSubmitButton = styled.div`
  position: fixed;
  bottom: 40px;
  width: calc(100% - 124px);
  background: linear-gradient(266.38deg, #8c86ff 0%, #ab5fb4 49.18%, #cc3767 100%);
  display: none;
  box-shadow: 1px 2px 80px rgba(11, 21, 40, 0.5);
  border-radius: 10px 2px 2px 2px;
  justify-content: center;
  color: #ffffff;
  text-shadow: 0px 0.698917px 43.6823px #ab9be9;
  font-size: 15px;
  height: 45px;
  align-items: center;
  ${media.mobile`
    display: flex;
 `}
`;

export const CloseFiltersButton = styled.div`
  color: #f1aebb;
  position: relative;
  right: -8px;
  width: 100%;
  display: none;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding: 8px;
  ${media.mobile`
    display: flex;
 `}
`;
export const MobileResetButton = styled.div`
  display: none;
  ${ResetFiltersButton} {
    margin-top: 16px;
  }
  ${media.mobile`
    display: flex;
    flex-direction:column;
 `}
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Outage';
  font-size: 38px;
  font-weight: bold;
  margin-top: 60px;
`;
export const VoxoIdSearchContainer = styled.div`
  max-width: ${size.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.mobile`
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-end;
    padding:24px 24px 24px 64px;
 `}
`;
export const VoxoIdWrapper = styled.div`
  width: 465px;
  height: 27px;
  border-bottom: 1px solid rgb(255 255 255 / 20%);
  margin-left: 33px;
  position: relative;
  ${media.mobile`
    width: 100%;
    margin-bottom:16px;
 `}
  > span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;
    position: absolute;
    top: 0px;
    top: -9px;
    background: linear-gradient(269deg, #f1aebb 1.22%, #8c86ff 98.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &::before {
    content: '';
    width: 39px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    transform: rotate(45deg);
    position: absolute;
    left: -33px;
    top: 12px;
  }
`;
export const VoxoIdSearchControls = styled.img`
  position: absolute;
  right: 0px;
  top: -14px;
  padding: 4px;
  cursor: ${({ isEmpty }) => (isEmpty ? 'unset' : 'pointer')};
  border-radius: 4px;
  &:hover {
    background: ${({ isEmpty }) => (isEmpty ? 'unset' : color.VOID_3)};
  }
`;
export const VoxoIdSearch = styled.input`
  width: 100%;
  height: 27px;
  border: none;
  background: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  top: -14px;
  outline: none;
  padding: 0 15px;
  background: linear-gradient(269deg, #f1aebb 1.22%, #8c86ff 98.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export const VoxoSearchHash = styled.div`
  position: absolute;
  top: -3px;
  left: 1px;
  color: #fff;
  font-size: 15px;
`;
export const VoxosLength = styled.div`
  font-size: 15px;
`;
export const LoadingWrapper = styled.div`
  width: calc(100% - 75px);
  height: 80px;
  border-radius: 10px;
  background: #0e1c34;
  display: flex;
  align-items: center;
  padding-left: 42px;
  text-shadow: 1px 2px 10px #d7597b;
  margin: 75px 0 0 32px;
  font-size: 16px;
  ${media.mobile`
    width: calc(100% - 56px);
 `}
  > img {
    width: 20px;
    margin-right: 16px;
  }
`;
export const VoxoGalleryWrapper = styled.div`
  width: 100%;
  pointer-events: ${({ showFilters }) => (showFilters ? 'none' : 'unset')};
`;
export const VoxoImageControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  align-items: flex-start;
`;

export const VoxoData = styled.div`
  display: flex;
  flex-direction: column;
  a {
    border-radius: 5px;
    padding: 4px 6px;
    position: relative;
    left: -6px;
    &:hover {
      background: #172a49;
    }
  }
  > span {
    color: #56f6ce;
    font-weight: 300;
    font-size: 12px;
    > span {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;

export const VoxoGridItemArchetype = styled.div`
  padding: 5px 0 10px 0;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.45;
`;
