import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { useRef, useState } from 'react';
import CHEVRON_DOWN from 'assets/icons/chevron_down.svg';
import { ArrowDown, ListItem, SelectBox, SelectContainer, SelectList, SelectValue } from './select.styled';

const Select = ({ data, value, onChange }) => {
  const selectRef = useRef(null);
  const [showList, toggleShowList] = useState(false);
  useOutsideClick(selectRef, toggleShowList);
  const handleSelect = item => {
    onChange(item);
    toggleShowList(false);
  };
  return (
    <SelectContainer ref={selectRef}>
      <SelectBox onClick={() => toggleShowList(!showList)}>
        <SelectValue>{value?.displayName || data[0]?.displayName}</SelectValue>
        <ArrowDown src={CHEVRON_DOWN} />
      </SelectBox>
      {showList && (
        <SelectList>
          {data.map(item => (
            <ListItem key={item.value} active={item.value === value} onClick={() => handleSelect(item.value)}>
              {item.displayName}
            </ListItem>
          ))}
        </SelectList>
      )}
    </SelectContainer>
  );
};
export default Select;
