import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Checkbox';
import { CheckboxContainer, CheckboxGroupLine, CheckboxGroupTitle, Input, MoreFiltersButton } from '../gallery.styled';

const factionMap = {
  'Angos': 'The Shen Tanks',
  'Astras': 'Polarion',
  'Corpo': 'Corpo Vendrix',
  'Verita': 'Luma Systemica',
};


const initialValueShowAll = (obj, max) => {
  return Object.values(obj)
    .slice(max - 1)
    .find(i => i === true);
};

const CheckboxGroup = ({ target, filters, setFilters, max, withSearch = false }) => {
  const [showAll, toggleShowAll] = useState(initialValueShowAll(filters[target], max));
  const [checkboxesList, setCheckboxesList] = useState(filters[target]);
  const [inputValue, setInputValue] = useState('');

  const handleCheckboxChange = (item, val, collection) => {
    if (item === 'all') {
      setFilters({
        ...filters,
        [target]: Object.keys(collection).reduce((out, key) => {
          out[key] = val;
          return out;
        }, {}),
      });
    } else {
      const cutCollection = Object.keys(collection).reduce((out, key) => {
        if (key !== item && key !== 'all') {
          out.push(collection[key]);
        }
        return out;
      }, []);
      if (cutCollection.every(i => i === val)) {
        setFilters({
          ...filters,
          [target]: { ...collection, all: val, [item]: val },
        });
      } else {
        setFilters({
          ...filters,
          [target]: { ...collection, all: false, [item]: val },
        });
      }
    }
  };
  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    const filteredCheckboxesList = Object.keys(filters[target])
      .filter(item => item.toLowerCase().includes(value.toLowerCase()))
      .reduce((out, c) => {
        out[c] = filters[target][c];
        return out;
      }, {});
    setCheckboxesList(filteredCheckboxesList);
  };
  return (
    <>
      <CheckboxGroupTitle>{target}</CheckboxGroupTitle>
      {withSearch && <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />}
      {Object.keys(checkboxesList).map(
        (item, i) =>
          (!max || showAll || i < max) && (
            <CheckboxContainer key={item}>
              <Checkbox
                label={factionMap[item] ? factionMap[item] : item.replace('_', ' ')}
                value={filters[target][item]}
                onChange={val => handleCheckboxChange(item, val, filters[target])}
              />
            </CheckboxContainer>
          ),
      )}
      {max && (
        <MoreFiltersButton onClick={() => toggleShowAll(!showAll)}>{showAll ? 'Hide' : 'See more'}</MoreFiltersButton>
      )}
      <CheckboxGroupLine />
    </>
  );
};
export default CheckboxGroup;
